import {IPageValueProvider} from './IPageValueProvider';
import {IMappingService} from './IMappingService';
import * as angular from "angular";


export class PageValueProvider<T> implements IPageValueProvider<T> {
    static $inject = ['mappingService'];

    private pageValueJsons: { [name: string]: {} } = {};
    private pageValues: { [name: string]: T } = {};

    constructor(private mappingService: IMappingService) {
    }

    getPageValue(name?: string): T {

        let pageValueName = name ? name : IPageValueProvider.defaultPageValueName;

        if (!this.pageValues[pageValueName]) {
            this.pageValues[pageValueName] = this.createPageValue(pageValueName);
        }

        return this.pageValues[pageValueName];
    }

    public createPageValue(name?: string): T {
        let pageValueName = name ? name : IPageValueProvider.defaultPageValueName;

        return <T> this.mappingService.fromJson(this.getPageValueJson(pageValueName));
    }

    private getPageValueJson(name: string) {
        if (!this.pageValueJsons[name]) {
            var pageValueString = angular.element('script#' + name).html();
            this.pageValueJsons[name] = JSON.parse(pageValueString);
        }

        return this.pageValueJsons[name];
    }
}
