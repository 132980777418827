import * as _ from "underscore";

/*
* Class decorator which expects classes with a static property of injectableName.
* The properties from the entering classes will be mapped to the $inject field to satisfy strict dependency injection of Angular.
* */
export function Injectables(...injectables: ({ new(...args: any[]): any; injectableName: string; } | string)[]) {
    let i = _.toArray(injectables);

    return function (target: any) {
        target['$inject'] = _.map(i, (arg: any) => arg.injectableName ? arg.injectableName : arg);
    };
}
