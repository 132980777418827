export class TrackingController {

    trackingPrefix: string;

    constructor() {
        this.trackingPrefix = null;
    }

    getPrefix(): string {
        return this.trackingPrefix;
    }

    setPrefix(prefix: string) {
        this.trackingPrefix = prefix;
    }

}
