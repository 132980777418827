import {FeedbackService} from '../../service/FeedbackService';
import * as _ from "underscore";
import {IAugmentedJQuery, IDirective} from "angular";
import * as $ from "jquery";
import feedbackPanelTemplate from "./feedbackPanelTemplate.html";


coFeedback.$inject = ['feedbackService'];

export function coFeedback(feedbackService: FeedbackService): IDirective {
    return {
        scope: {
            messageType: '@coFeedbackMessageType',
        },
        template: feedbackPanelTemplate,
        link: function (scope: any, $element: IAugmentedJQuery) {

            if (!_.contains(['info', 'warn', 'error'], scope.messageType)) {
                throw new Error('Unexpected message type found: ' + scope.messageType);
            }

            function scrollToFeedback(): void {
                $('html, body').stop(); // stop all animations that might be running - we want to scroll directly to the panel
                $('html, body').animate({scrollTop: $element.offset().top - 50}, 1000);
            }

            function updateScope(): void {
                // rebind messages
                // so we don't rely on the fact that the same messages instance is reused
                let messages = [];
                switch (scope.messageType) {
                    case 'info':
                        messages = feedbackService.messages.info;
                        break;
                    case 'warn':
                        messages = feedbackService.messages.warn;
                        break;
                    case 'error':
                        messages = feedbackService.messages.error;
                        break;
                }
                scope['messages'] = messages;

                if (!_.isEmpty(messages)) {
                    scrollToFeedback();
                }
            }

            // setting up watch for updating view and scrolling
            scope.$watch(() => feedbackService.messages, updateScope, true);
            updateScope();
        }
    };
}
