import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {PostCall} from '../../domain/Call';
import {IAugmentedJQuery} from "angular";

/**
 * A Constraint that resolves its result from a remote call instead of a local validation.
 */
export class CallConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:CallConstraint', {
        key: 'string',
        call: 'PostCall'
    });

    public constructor(public key: string, public call: PostCall<any>) {
    }

    /**
     * Not used for call constraints. Validation is done in @see FieldController.
     */
    public applies(value: any): boolean {
        throw new Error('not used for call constraints');
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
