import {IConstraint} from './constraints/IConstraint';
import {ValueMetaInfo} from '../decorators/ValueMetaInfo';
import {ValueMetaProperty} from '../decorators/ValueMetaProperty';
import {RequiredConstraint} from './constraints/RequiredConstraint';

@ValueMetaInfo('infrastructure:FieldConfiguration')
export class FieldConfiguration {

    @ValueMetaProperty('string | null')
    name: string;

    @ValueMetaProperty()
    enabled: boolean;

    @ValueMetaProperty()
    visible: boolean;

    @ValueMetaProperty('{}[]')
    constraints: IConstraint[];

    public constructor(name: string, enabled: boolean, visible: boolean, constraints: IConstraint[]) {
        this.name = name;
        this.enabled = enabled;
        this.visible = visible;
        this.constraints = constraints;
    }

    public allowsValidation(): boolean {
        return this.visible && this.enabled;
    }

    public isRequired(): boolean {
        return this.constraints.some(e => e instanceof RequiredConstraint);
    }
}
