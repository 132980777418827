import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import * as _ from "underscore";
import {IAugmentedJQuery} from "angular";


export class DateRangeConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:DateRangeConstraint', {
        key: 'string',
        lowerBound: 'date | null',
        upperBound: 'date | null'
    });


    public constructor(public key: string, private lowerBound: Date, private upperBound: Date) {
    }

    public isRange(): boolean {
        return this.lowerBound != null && this.upperBound != null;
    }

    public isUpperBound() {
        return !this.isRange() && this.upperBound != null;
    }

    public isLowerBound() {
        return !this.isRange() && this.lowerBound != null;
    }

    public applies(value: any): boolean {
        if (!_.isDate(value)) {
            //required validation is handled elsewhere...
            return true;
        }
        if (this.isLowerBound()) {
            return this.lowerBound <= value;
        } else if (this.isUpperBound()) {
            return this.upperBound >= value;
        } else if (this.isRange()) {
            return this.lowerBound <= value && this.upperBound >= value;
        }
        return true;
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
