import {FlowIdService} from "../service/FlowIdService";
import {MockSettingsService} from "../service/MockSettingsService";


export class HeaderInterceptor {

    static $inject = ['flowIdService', 'mockSettingsService'];

    constructor(private flowIdService: FlowIdService, private mockSettingsService: MockSettingsService) {

        // interceptors are called with functions without object handle eg.
        // flowInterceptor = ...
        // fn = flowInterceptor.request;
        // fn(request);

        // use don't use bind to access this because phantomjs dont support this...
        this['request'] = function (config: ng.IRequestConfig): ng.IRequestConfig {
            let flowId = flowIdService.getFlowId();
            if (flowId) {
                config.headers['flowId'] = flowId;
            }
            let mockSettings = mockSettingsService.getMockSettings();
            if (mockSettings) {
                config.headers['X-MOCK'] = mockSettings;
            }
            return config;
        };
    }

}
