import {valueMetaInfo, MetaInfo} from './MetaInfo';



/**
 * This class is used on the client side for a ResolvedLink which is generated in the JSON Mapping from a Link object
 * and LinkResolver
 */
export class Link {

    static META_INFO = () => valueMetaInfo('infrastructure:Link', {
        uri: 'string',
        mediaType: 'string | null'
    });

    constructor(public uri: string, public mediaType: string) {
        argsContract(arguments, 'string, string | null');
    }

}
