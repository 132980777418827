
import {CsiWebpackModule} from "@shop/js-infrastructure-generic-csi";
import {ILogger, ILoggerService} from "../../service/LoggerService";
import {ITranscludeFunction} from "angular";
import {CsiAppController} from "./CsiAppController";

coCsiApp.$inject = ["loggerService"]
export function coCsiApp(loggerService: ILoggerService): ng.IDirective {

    let logger = loggerService.create("common.coCsiApp");
    let warningLoggedForCsi ={};

    return {
        scope: {
            moduleName: '@',
            config: '&',
            load: '&'
        },
        transclude: true,
        controller: CsiAppController,

        link: function (scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: ng.IController, transcludeFn: ITranscludeFunction) {
            const moduleName = scope.moduleName;
            if(!moduleName){

                throw new Error("Attribute 'module-name' must be set for directive 'co-csi-app'!");
            }
            const controller = <CsiAppController> ctrl;

            let webpackModule = <CsiWebpackModule>window[moduleName];

            if(!webpackModule){
                // only log once...
                if(!warningLoggedForCsi[moduleName]){
                    logger.warn("CSI module '" + moduleName + "' not found in global scope!, Ignoring ...");
                    warningLoggedForCsi[moduleName] = true;
                }
                element.append(transcludeFn());
                scope.load({error: "CSI Module not available in root scope!"})
            } else {
                element.children().remove();
                let config = null;
                if(scope.config){
                    config = scope.config.apply();
                }

                let csiPromise = webpackModule.bootstrap(element[0], config);
                csiPromise.then((csiApp) => {
                    controller.initCsiApp(moduleName, csiApp);
                    if(scope.load){
                        scope.load({app: csiApp});
                    }
                })
            }
        }
    };
}


