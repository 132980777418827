import {IRootScopeService} from "angular";

export interface ISnapshotService {
    snapshot()
}

export class SnapshotService implements ISnapshotService {
    static $inject = ['$rootScope'];

    private snapShotRequested: boolean = false;
    private digestCycleRun: boolean;

    constructor(private $rootScope: IRootScopeService) {

        $rootScope.$watch(() => {
            this.digestCycleRun = true;
        });
    }

    private triggerSnapShot() {
        if (typeof window['callPhantom'] == 'function') {
            window['callPhantom']();
        }
        this.snapShotRequested = false;
    }

    /**
     * we use setTimeout and not the $timeout service as $timeout triggers a digest cycle
     * and we would get caught in an infinite loop
     */
    private triggerSnapshotIfNoDigestCycleRun() {
        if (this.snapShotRequested && !this.digestCycleRun) {
            this.triggerSnapShot();
        } else {
            this.digestCycleRun = false;
            window.setTimeout(() => {
                this.triggerSnapshotIfNoDigestCycleRun()
            }, 0);
        }
    }

    snapshot() {
        this.snapShotRequested = true;
        this.$rootScope.$evalAsync(() => { // fire after $digest
            window.setTimeout(() => {
                this.triggerSnapshotIfNoDigestCycleRun()
            }, 0);
        });
    }
}
