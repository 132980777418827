import {FieldController} from './FieldController';
import {IAttributes, IAugmentedJQuery, IDirective, IFormController, IScope} from "angular";


/**
 *
 * This directive marks a 'logical field' on a container element which contains the label and optionally the input of
 * this field.
 *
 * The marked element is hidden by adding 'ng-hide' if the field is configured as invisible.
 */
export function coField(): IDirective {
    return {
        scope: {
            fieldName: '@coField',
        },

        controller: <any>FieldController,
        require: ['coField', '^?form'],
        controllerAs: 'controller',
        bindToController: true,

        link: function (scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, controllers: any[]) {
            var myController: FieldController = controllers[0];
            var formController: IFormController = controllers[1];

            // this style of controller lookup is used by the angular infrastructure to connect a form controller to its parent
            // the normal require handle the lookup for the parent wrong and injects the own controller a second time...
            var parentController: FieldController = (<IAugmentedJQuery>element.parent()).controller('coField');

            // initialise own controller with its parent and formController
            // explicitly pass null to ease debugging
            myController.init(parentController || null, formController || null);

            /**
             * we need the watchers here, as this is a timing issue.
             * at the time this link function is used for the first time,
             * the
             */
            // hide element if not visible
            scope.$watch(
                () => (myController.isFieldVisible()),
                (visible: boolean) => {
                    if (!myController.isFieldVisible()) {
                        element.addClass("ng-hide")
                    }
                }
            );

            //OSD-360 - set field disabled - investigate IE11 - see
            // https://connect.microsoft.com/IE/feedbackdetail/view/962368/can-still-edit-input-type-text-within-fieldset-disabled
            scope.$watch(
                () => (myController.isFieldEnabled()),
                (enabled: boolean) => {
                    if (!enabled) {
                        element.find("fieldset").attr("disabled", "disabled");
                    }
                }
            );
        }
    };
}
