import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class RegExpConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:RegExpConstraint', {
        key: 'string',
        pattern: 'string',
        ignoreCase: 'boolean'
    });

    public constructor(public key: string, private pattern: string, private ignoreCase: boolean) {

    }

    public applies(value: any): boolean {
        if (!value || value.length === 0) {
            return true;
        }
        var regexFlags = this.ignoreCase ? "i" : "";
        return new RegExp(this.pattern, regexFlags).test(value);
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
