
import * as _ from "underscore"

export class DayOfWeekType {

    private static _values: { [s: string]: DayOfWeekType; } = {};

    public static forName(obj): DayOfWeekType {
        argsContract(arguments, 'str');
        if (obj === null) {
            return null;
        }
        return DayOfWeekType._values[obj];
    }

    public static values(): DayOfWeekType[] {
        return _.values(DayOfWeekType._values);
    }

    // index mapping based on JS standard
    public static MONDAY = new DayOfWeekType("MONDAY", 1);
    public static TUESDAY = new DayOfWeekType("TUESDAY", 2);
    public static WEDNESDAY = new DayOfWeekType("WEDNESDAY", 3);
    public static THURSDAY = new DayOfWeekType("THURSDAY", 4);
    public static FRIDAY = new DayOfWeekType("FRIDAY", 5);
    public static SATURDAY = new DayOfWeekType("SATURDAY", 6);
    public static SUNDAY = new DayOfWeekType("SUNDAY", 0);

    constructor(public name: string, public index: number) {
        argsContract(arguments, 'str, number');
        DayOfWeekType._values[name] = this;
    }
}
