import {IDirective} from "angular";

coInitializeExternalContent.$inject = ['$window'];

/**
 * This directive should be used on DOM elements whose children potentially contain
 * external content (i.e. CMS teasers, adspaces, ...) which might have to be initialized
 * by the Compendium JS (webapp.js).
 * The directive registers a MutationObserver on the given element and triggers a call to
 * webapp.initializePlugins() if the child nodes are changed.
 */
export function coInitializeExternalContent(windowService: ng.IWindowService): IDirective {
    function initExternalApps(): void {

        let webApp: any = windowService['webapp'];

        if (webApp && webApp.initializePlugins) {
            webApp.initializePlugins();
        }
    }

    return {
        link: (scope: any, element: ng.IAugmentedJQuery): void => {
            let nativeElement = element.get(0);
            // we are interested in changes to the child nodes (childList) and
            // their descendants (subtree)
            let config = {childList: true, subtree: true};

            let observer = new MutationObserver(mutations => {
                initExternalApps();
            });

            observer.observe(nativeElement, config);

        }
    };
}