


/**
 * This function is used to construct a new {@link MetaInfo} instance for a value class.
 * @param typeName logical name of this class, this should match the name of the constructor function.
 * @param propertyNamesAndContracts a hash object which keys are the property names of the object and its values
 * are a string that describes the contract of this field {@see https://github.com/okrammer/cs-args-contract}.
 */
export function valueMetaInfo(typeName: string, propertyNamesAndContracts: { [propertyName: string]: string }) {
    argsContract(arguments, 'string, {*: string}')
    return new MetaInfo(typeName, MetaType.VALUE, propertyNamesAndContracts)
}

/**
 * This function is used to construct a new {@link MetaInfo} instance for a enum class.
 * @param typeName logical name of this class, this should match the name of the constructor function.
 */
export function enumMetaInfo(typeName: string) {
    argsContract(arguments, 'string')
    return new MetaInfo(typeName, MetaType.ENUM, null);
}


/**
 * This class encapsulates the meta data of a class that is used to reflect apon it's properties.
 */
export class MetaInfo {
    constructor(public typeName: string,
                public typeMetaType: MetaType,
                public propertyNamesAndContracts: { [propertyName: string]: string }) {
        argsContract(arguments, 'string, MetaType, {*: string} | null')

    }
}

/**
 * This enum defines two types of classes one for regular values (eg. Entities) and enums.
 */
export class MetaType {

    private static _values: { [key: string]: MetaType } = {};

    static ENUM: MetaType = new MetaType('ENUM');
    static VALUE: MetaType = new MetaType('VALUE');

    constructor(public name: string) {
        argsContract(arguments, 'string')
        MetaType._values[name] = this;
    }
}
