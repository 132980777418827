import * as _ from "underscore";
import {TrackingProductLine, TrackingProductLineName} from "../..";


export type TrackingProductLineControllerBindingType = TrackingProductLineName | TrackingProductLineName []
    | TrackingProductLine | TrackingProductLine[] | null | undefined;

export class TrackingProductLineController {

    private _coTrackingProductLines: TrackingProductLineControllerBindingType;
    private productLines: TrackingProductLine[];

    set coTrackingProductLines(value: TrackingProductLineControllerBindingType) {
        this._coTrackingProductLines = value;

        // reset product lines if an error occurs
        this.productLines = [];

        if (!value) {
            return;
        }

        // first normalize the array / single element part
        let array = _.isArray(value) ? value : [value];

        // then normalize the element types
        // supported values are strings with the value of the enum instance names for use in templates or
        // the enum instances for use in typescript code like controllers.
        let arrayOfEnum = array.map((element) => {
            if (element instanceof TrackingProductLine) {
                // nothing to do ...
                return element;
            }
            if (_.isString(element)) {
                // try parse the the string ...
                let trackingProductLine = TrackingProductLine.forName(element);
                if (!trackingProductLine) {
                    throw new Error(`${element} is not a valid product line!`);
                }
                return trackingProductLine;
            }
            throw new Error(`Unsupported value type ${typeof element}`);
        });
        this.productLines = arrayOfEnum;
    }

    get coTrackingProductLines(): TrackingProductLineControllerBindingType {
        return this._coTrackingProductLines;
    }

    get productLinesTrackingString() {
        return (this.productLines || []).map(productLine => productLine.trackingValue).sort().join(",");
    }


}