import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

/**
 * has no real functionality. Only used to provide and map propper feedback messages.
 * Constraint is used in conjunction with coFieldEqualityValidation-Directive.
 */
export class ClientsideFieldEqualityConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:ClientsideFieldEqualityConstraint', {});

    constructor(public key: string) {
    }

    public applies(value: any): boolean {
        return true
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
