import {TrackingController} from './TrackingController';
import {IDirective} from "angular";

export function coTrackingId(): IDirective {
    return {
        controller: <any>TrackingController,
        link: <any> {
            pre: function (scope: any, element: ng.IAugmentedJQuery, attr, trackingController: TrackingController) {
                (function handleTrackingValue() {
                    var idPrefix = attr['coTrackingId'];

                    var parentController: TrackingController = (<ng.IAugmentedJQuery>element.parent()).controller('coTrackingId');

                    if (idPrefix) {
                        if (parentController) {
                            trackingController.setPrefix(parentController.getPrefix() + '.' + idPrefix);
                        }
                        else {
                            trackingController.setPrefix(idPrefix);
                        }
                        element.attr('data-tracking', trackingController.getPrefix());
                    }
                })();
            }
        }
    };
}
