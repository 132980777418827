import {IAugmentedJQuery, IDirective} from "angular";

export function coClickPreventDefault(): IDirective {
    return {
        link: (scope: any, $element: IAugmentedJQuery) => {
            $element.on('click', (e: JQuery.Event) => {
                e.preventDefault();
            });
        }
    }
}
