import {ILogger, ILoggerService} from './LoggerService';

export interface IPostUrlService {
    buildFormAndPostToURL(data: { [key: string]: string }, targetURL: string): void

    setFormSubmitCallback(callback: (data: { [key: string]: any }, targetURL: string) => void)

    resetCallback();
}

export class PostUrlService implements IPostUrlService {
    static $inject = ['loggerService'];

    private logger: ILogger;
    private callback: (data: { [key: string]: any }, targetURL: string) => void = null;

    constructor(loggerService: ILoggerService) {
        this.logger = loggerService.create('commerce.common.PostURLService');
    }

    buildFormAndPostToURL(data: { [key: string]: any }, targetURL: string) {
        this.callback(data, targetURL);
    }

    setFormSubmitCallback(callback: (data: { [key: string]: any }, targetURL: string) => void) {

        if (this.callback) {
            this.logger.error('callback already registered by another dude.', {callback: callback});
            throw new Error('callback already registered by another dude.')
        }
        this.callback = callback;
    }

    resetCallback() {
        this.callback = null;
    }
}
