import {IDocumentService} from "angular";


export class FlowIdService {

    static $inject = ['$document'];

    private flowIdString;

    constructor($document: IDocumentService) {
        var metaElement = $document.find('meta[name=flowId]');
        if (metaElement.length !== 0) {
            this.flowIdString = metaElement.attr("content");
        }
    }

    getFlowId(): string {
        return this.flowIdString;
    }

}
