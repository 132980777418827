export interface IAdaptiveService {
    setBreakpoint(screenWidth: number): void;

    overrideBreakpoint(breakpoint: string): void;

    getBreakpoint(): string;

    getWidthForBreakpoint(breakpoint: string): number;

    checkBreakpoints(breakpoints: string[]): boolean;
}

export class AdaptiveState {
    currentBreakpoint: string;
    breakpoints: {
        lg: number;
        md: number;
        sm: number;
        xs: number;
    }
}
