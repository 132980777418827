import {enumMetaInfo, MetaInfo} from '../domain/MetaInfo';
import * as _ from "underscore";


/**
 * Generic Status enum for a executed validation.
 * A Validation can either be succesful / failed or there was an error.
 */
export class ValidationStatus {

    static META_INFO: MetaInfo = enumMetaInfo('infrastructure:ValidationStatus');

    private static _values: { [s: string]: ValidationStatus; } = {};

    static forName(obj): ValidationStatus {
        argsContract(arguments, 'str');
        if (obj === null) {
            return null;
        }
        return ValidationStatus._values[obj];
    }

    static values(): ValidationStatus[] {
        return _.values(ValidationStatus._values);
    }


    static SUCCESS: ValidationStatus = new ValidationStatus('SUCCESS');
    static FAILED: ValidationStatus = new ValidationStatus('FAILED');
    static ERROR: ValidationStatus = new ValidationStatus('ERROR');

    constructor(public  name: string) {
        argsContract(arguments, 'str');
        ValidationStatus._values[name] = this;
    }

}
