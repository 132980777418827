/**
 * This interface is used to type a constructor function that has a META_INFO field for the metaData information.
 * The field can be the MetaInfo itself of a function that construct the metaInfo lazy, this can be required if the
 * order in which the files are concatenated is not specified.
 */
import {IServiceProvider} from "angular";
import {MetaInfo} from "..";

/**
 * A constructor function with a META_INFO property.
 * Don't use NewableType here because Enums will not have a accessible constructor.
 */
export interface AnnotatedConstructor extends Function {

    prototype: {}

    META_INFO?: MetaInfo | (() => MetaInfo)
}

export interface IMappingServiceProvider extends IServiceProvider {

    /**
     * This function is used to register a constructor that has a metaInfo 'annotation', this done be defining a static
     * META_INFO field on the class by calling {@link commerce.common.valueMetaInfo()} or
     * {@link commerce.common.enumMetaInfo()}
     * @param ctor a constructor with the META_INFO field.
     */
    addAnnotatedType(ctor: AnnotatedConstructor): void

    /**
     * This function is used to register a non standard javascript type (eg. Date, Regex, etc.).
     * @param typeName The logical type name that is used to mark the type in json.
     * @param isType A function that is used to check for this type in the object graph when it is serialized
     *              _to_ JSON.
     * @param fromJson A function that can construct an instance of the registered type from a deserialized
     *                 JSON object.
     * @param toJson A function that is used to construct a javascript object that is serialized to JSON from an
     *               instance of the registered type.
     */
    addCustomType<T>(typeName: string, isType: (obj: T) => boolean, fromJson: (json: any) => T, toJson: (obj: T) => any): void

    /**
     * This function is used to instantiate a new MappingService instance.
     * Primary for testing purpose.
     */
    createMappingService(loggerService): IMappingService;


}

export class IMappingServiceProvider {
    static injectableName: string = 'mappingServiceProvider';
}

/**
 * This service is used to map between value classes in javascript and javascript objects that are deserialized from
 * JSON. Any type that should be serialized or deserialized must be registered first by the provider via a call to
 * {@link #addAnnotatedType} or {@link #addCustomType}.
 */
export interface IMappingService {


    /**
     * This function takes a javascript object graph that is returned form json deserialization and creates
     * a new typed object graph based on the registered types.
     */
    fromJson(json: any, config?: IMappingConfig): any;

    /**
     * This function takes a typed object graph based on the registered types and returns a javascript object graph
     * that can be serialized to json.
     */
    toJson(value: any, config?: IMappingConfig): any;
}

export interface IMappingConfig {
    path?: string
    failOnUndefined?: boolean
    validate?: boolean
}
