import {IController, IDirective, IScope} from "angular";
import {ITrackingAttributesService} from "../..";
import {TrackingProductLineController} from "./TrackingProductLineController";
import * as _ from "underscore";
import {CsiApp} from "@shop/js-infrastructure-generic-csi";
import {CsiAppController} from "../csiApp/CsiAppController";
/**
 * This directive is used to add two tracking-attributes to a given element.
 * Those attributes are: "data-tracking-action" and "data-tracking-description" (see OSD-2338)
 *
 * - coTrackingAttributesAction: used to provide the type of element this directive is used on (despite its name)
 * - coTrackingElementId: used to provide an ID for the element this directive is used on
 *
 */

const MESSAGE_TYPE = 'tracking-product-lines';

export function coTrackingAttributesCsi(): IDirective {

    return {
        require: ['^?coTrackingProductLines', 'coCsiApp'], // require the next coTrackingProductLiensController, going upward
        link: (scope: IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, controllers: IController[]): void => {

            // since this directive doesn't have a controller, we can access the parent controller at [0]
            const productLinesController = <TrackingProductLineController> controllers[0];
            const csiAppController = <CsiAppController> controllers[1];

            if (!productLinesController) {
                // if we throw an error in this case all tests must be wrapped in coTrackingProductLines directive
                // so we only log an error and ignore this ...
                console.error("Can't render coTrackingAttributes on element - no coTrackingProductLines directive found any parent element", {element});
                return;
            }

            scope.$watch(() => productLinesController.productLinesTrackingString, (newProductLineString: string) => {
                csiAppController.send(<any>{
                    type: MESSAGE_TYPE,
                    value: newProductLineString
                })
            });
        }
    };
}