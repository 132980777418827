import {Link} from './Link';
import {valueMetaInfo, MetaInfo} from './MetaInfo';




/**
 * This class is used to abstract a call to the server and encapsulates information like url, http method,
 * or data to post. Typically instances of call are created on the server side. The client chooses the appropriate
 * call instance for an action and calls the {@link ICallService} to execute the call and return the result of this
 * call.
 */
export class Call<T> {

    static fromObj<T>(obj): Call<T> {
        if (obj.method === 'POST') {
            return Call.post<T>(obj.link, obj.data);
        }
        else if (obj.method === 'DELETE') {
            return Call.delete(obj.link);
        }
        else {
            return Call.get<T>(obj.link);
        }
    }

    static post<T>(url: string, data: any): PostCall<T> {
        return new PostCall<T>(new Link(url, null), data);
    }

    static get<T>(url: string): GetCall<T> {
        return new GetCall<T>(new Link(url, null));
    }

    static delete<T>(url: string): DeleteCall<T> {
        return new DeleteCall<T>(new Link(url, null));
    }

    static navigateTo(url: string): ConstantCall<NavigationEvent> {
        var navigationEvent: NavigationEvent = new NavigationEvent(new Link(url, null));
        return new ConstantCall<NavigationEvent>(navigationEvent);
    }

    static printCall<T>(call: Call<T>): string {
        if (call instanceof AbstractCall) {
            var abstractCall: AbstractCall<T> = <AbstractCall<T>>call;
            if (abstractCall instanceof GetCall) {
                var getCall: GetCall<T> = <GetCall<T>>abstractCall;
                return 'GET ' + getCall.link.uri;
            }
            if (abstractCall instanceof PostCall) {
                var postCall: PostCall<T> = <PostCall<T>>abstractCall;
                return 'POST (' + JSON.stringify(postCall.data) + ')' + postCall.link.uri;
            }
            if (abstractCall instanceof DeleteCall) {
                var deleteCall: DeleteCall<T> = <DeleteCall<T>>abstractCall;
                return 'DELETE' + deleteCall.link.uri;
            }
        }

        if (call instanceof ConstantCall) {
            var constantCall: ConstantCall<T> = call;
            var payload: T = constantCall.constantPayload;
            if (payload instanceof NavigationEvent) {
                var navigationEvent: NavigationEvent = <NavigationEvent><any>payload;
                return 'ConstantCall:NavigationEvent:' + navigationEvent.link.uri;
            }
            if (payload instanceof FormSubmitEvent) {
                var formSubmitEvent: FormSubmitEvent = <FormSubmitEvent><any>payload;
                return 'ConstantCall:FormSubmitEvent:' + formSubmitEvent.link.uri + ', data ' + JSON.stringify(formSubmitEvent.data);
            }
            else {
                var typeName = Object.prototype.toString.call(payload).slice(8, -1);
                return 'ConstantCall:' + typeName;
            }
        }

        throw new Error('unknown call type')
    }
}

export class AbstractCall<T> extends Call<T> {
    constructor(public link: Link) {
        super();
        argsContract(arguments, 'Link');
    }
}

export class GetCall<T> extends AbstractCall<T> {
    static META_INFO = () => valueMetaInfo('infrastructure:GetCall', {
        link: 'Link'
    });

    constructor(link: Link) {
        super(link);
        argsContract(arguments, 'Link');
    }

    withVariables(variables: { [key: string]: string }): GetCall<T> {

        var urlWithPattern = this.link.uri;
        var resultUrl = urlWithPattern.replace(/_(\w+)_/g, function (match, group1) {
            return variables[group1];
        });

        return new GetCall(new Link(resultUrl, this.link.mediaType));
    }
}

export class PostCall<T> extends AbstractCall<T> {
    static META_INFO = () => valueMetaInfo('infrastructure:PostCall', {
        link: 'Link',
        data: 'any & !undefined'
    });

    constructor(link: Link, public data: any) {
        super(link);
        argsContract(arguments, 'Link, any');
    }

    withData(data: any): PostCall<T> {
        return new PostCall(this.link, data);
    }
}

export class DeleteCall<T> extends AbstractCall<T> {
    static META_INFO = () => valueMetaInfo('infrastructure:DeleteCall', {
        link: 'Link'
    });

    constructor(link: Link) {
        super(link);
        argsContract(arguments, 'Link');
    }
}

export class ConstantCall<T> extends Call<T> {
    static META_INFO = () => valueMetaInfo('infrastructure:ConstantCall', {
        constantPayload: 'any'
    });

    constructor(public constantPayload: T) {
        super();
        argsContract(arguments, 'any');

    }
}

export class NavigationEvent {
    static META_INFO = () => valueMetaInfo('infrastructure:NavigationEvent', {
        link: 'Link'
    });

    constructor(public link: Link) {
        argsContract(arguments, 'Link');
    }
}

export class FormSubmitEvent {
    static META_INFO = () => valueMetaInfo('infrastructure:FormSubmitEvent', {
        link: 'Link',
        data: 'any & !undefined'
    });

    constructor(public link: Link, public data: any) {
        argsContract(arguments, 'Link', 'any');
    }
}
