import {IPromise} from "angular";

export interface IBusyService {

    busy<T>(promise: IPromise<T>, config?: {
        title: string,
        subtitle: string,
        titleSlow: string,
        subtitleSlow: string,
        slowTimeout: number
    }): IPromise<T>;

    busyWithPreservedPromise<T>(promise: IPromise<T>, config?: {
        title: string,
        subtitle: string,
        titleSlow: string,
        subtitleSlow: string,
        slowTimeout: number
    }): IPromise<T>;

    isBusy(): boolean;

    title: string;
    subtitle: string;
    titleSlow: string;
    subtitleSlow: string;
    slowTimeout: number;

}

export class IBusyService {
    static injectableName: string = 'busyService';
}
