export interface IPageValueProvider<T> {

    /**
     * This method reads the pageValue from the page and returns with every invocation the same mapped instance.
     */
    getPageValue(name?: string): T

    /**
     * This method creates for every call a new mapped pageValue instance.
     */
    createPageValue(name?: string): T
}

export class IPageValueProvider<T> {
    static injectableName: string = 'pageValueProvider';
    static defaultPageValueName: string = 'pageValue';
}
