import {IExternalService} from '../../service/IExternalService';
import {IAttributes, IDirective} from "angular";
import coModalDialogTemplate from "./coModalDialogTemplate.html";

export function coModalDialog(externalService: IExternalService): IDirective {
    return {
        scope: {
            open: '&coModalDialogOpen',
            onClose: '&coModalDialogOnClose',
            title: '@coModalDialogTitle',
            limitHeight: '=coModalDialogLimitHeight'
        },
        transclude: true,
        template: coModalDialogTemplate,
        replace: true,
        link: function (scope: any, element: any, attrs: IAttributes, ctrl, transcludeFn) {
            let callOpenClose = () => {
                if (scope.open()) {
                    element.modal('show');
                } else {
                    element.modal('hide');
                }
            };

            element.modal({show: false});
            callOpenClose();

            element.on('hidden.bs.modal', () =>
                scope.$apply(() => {
                    scope.onClose();
                    callOpenClose();
                })
            );

            if (scope.limitHeight) {
                let limitModalHeight = () => {
                    element.find('.modal-body').css({
                        maxHeight: $(window).innerWidth()<768 ? $(window).height() : $(window).height() - 120,
                        overflowX: 'hidden'
                    });
                };

                element.on('show.bs.modal', () => {
                    limitModalHeight();
                });

                $(window).on('resize', () => {
                    limitModalHeight();
                });
            }

            scope.$watch(
                function () {
                    return scope.open();
                },
                function (newValue: boolean) {
                    callOpenClose();
                });
        }
    };
}

coModalDialog.$inject = [IExternalService.injectableName];
