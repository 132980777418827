/// <reference types="@shop/args-contract-types" />
/// <reference types="@types/node" />

import * as angular from "angular";
import "reflect-metadata";
// imports to tell browserify we want to include this modules ...
import {loadModuleCommerceCommon} from './common_module';
import {loadModuleCommerceCommonValidationService} from './common_validation_service_module';
import {loadModuleCommerceCommonValidation} from './common_validation_module';
import {loadModuleCommerceCommonValidationSemantic} from './common_validation_semantic_module';

export {valueMetaInfo, enumMetaInfo} from './domain/MetaInfo';
export {classToFactory} from './global/coNg';
export {tracking} from './global/tracking';
export {createUtils, IUtilsService} from './service/Utils';
export {createLoggerService, ILogger, ILoggerService} from './service/LoggerService';
export {CallService} from './service/CallService';
export {PageValueProvider} from './service/PageValueProvider';
export {IPageValueProvider} from './service/IPageValueProvider';
export {TrackingService} from './service/TrackingService';
export {IMappingServiceProvider} from './service/IMappingService';
export {IValidationRepositoryProvider} from './validation/IValidationRepositoryProvider';
export {validationRepositoryProvider} from './validation/ValidationRepositoryProvider'
export {coModalInfo} from './directive/modalInfo/coModalInfo';
export {coTooltip} from './directive/tooltip/coTooltip';
export {coScrollTo} from './directive/scrollTo/coScrollTo';
export {coCallAction} from './directive/callAction/coCallAction';
export {floatLabel} from './directive/floatLabel/floatLabel';
export {coInitializeExternalContent} from './directive/initializeExternalContent/coInitializeExternalContent';
export {coTrackingAttributes} from './directive/trackingAttributes/coTrackingAttributes';
export {
    ConstantCall,
    DeleteCall,
    GetCall,
    PostCall,
    NavigationEvent,
    FormSubmitEvent,
    Call,
    AbstractCall
} from './domain/Call';
export {
    Content,
    ResolvedContent,
    LazyContent
} from './domain/Content';
export {GenericRequestError} from './domain/GenericRequestError';
export {Link} from './domain/Link';
export {TrackingInfoValue} from './domain/TrackingInfoValue';
export {FieldConfiguration} from './validation/FieldConfiguration';
export {AcceptanceConstraint} from './validation/constraints/AcceptanceConstraint';
export {NumericConstraint} from './validation/constraints/NumericConstraint';
export {StringLengthConstraint} from './validation/constraints/StringLengthConstraint';
export {RequiredConstraint} from './validation/constraints/RequiredConstraint';
export {RegExpConstraint} from './validation/constraints/RegExpConstraint';
export {PreventSpecialCharConstraint} from './validation/constraints/PreventSpecialCharConstraint';
export {NumberRangeConstraint} from './validation/constraints/NumberRangeConstraint';
export {DateRangeConstraint} from './validation/constraints/DateRangeConstraint';
export {DateExcludedDatesConstraint} from './validation/constraints/DateExcludedDatesConstraint';
export {DateDaysOfWeekConstraint} from './validation/constraints/DateDaysOfWeekConstraint';
export {ValidDateConstraint} from './validation/constraints/ValidDateConstraint';
export {NameConstraint} from "./validation/constraints/NameConstraint";
export {EmailConstraint} from './validation/constraints/EmailConstraint';
export {InvisibleFieldMappedConstraint} from './validation/constraints/InvisibleFieldMappedConstraint';
export {ConditionalConstraint} from './validation/constraints/ConditionalConstraint';
export {CallConstraint} from './validation/constraints/CallConstraint';
export {coField} from './validation/directive/coField/coField';
export {coFieldInput} from './validation/directive/coField/coFieldInput';
export {coFieldMessage} from './validation/directive/coField/coFieldMessage';
export {coFieldPopover} from './validation/directive/coField/coFieldPopover';

export {snapshot} from './global/snapshot';
export {coClickPreventDefault} from './directive/clickPreventDefault/coClickPreventDefault';
export {ExternalService} from './service/ExternalService';
export {Injectables} from './decorators/Injectables';
export {ValueMetaInfo} from './decorators/ValueMetaInfo';
export {ValueMetaProperty} from './decorators/ValueMetaProperty';
export {DebugToggle} from './decorators/DebugToggle';
export {IExternalService} from './service/IExternalService';
export {ICallService} from './service/ICallService';
export {IBusyService} from './service/IBusyService';
export {IFeedbackService} from './service/IFeedbackService'
export {IEventService} from './service/IEventService';
export {IContentService} from './service/IContentService';
export {ISnapshotService} from './service/SnapshotService';
export {IMappingService} from './service/IMappingService';
export {ITrackingService} from './service/ITrackingService';
export {ITrackingAttributesService} from "./service/ITrackingAttributesService";
export {TrackingAttributesService} from "./service/TrackingAttributesService";
export {IAdaptiveService} from './service/IAdaptiveService';
export {IRenderModeService} from './service/IRenderModeService';
export {IPostUrlService} from './service/PostURLService';
export {IValidationRepository} from './validation/IValidationRepository';
export {PiranhaLoginFormDataValue} from './domain/PiranhaLoginFormDataValue';
export {IConstraint} from "./validation/constraints/IConstraint";
export {IDebugInfoService} from "./service/IDebugInfoService";
export * from "./service/IFlowIdService";
export * from "./domain/Trackable";
export * from "./service/RelativLocationService";
export * from "./domain/Html";
export * from "./domain/Url";

// Exports for tests
export * from './directive/loader/coLoader';
export * from './directive/tracking/coTrackingId';
export * from './service/FeedbackService';
export * from './service/TrackingService';
export * from './service/TrackingAttributesService';
export * from './service/AdaptiveService';
export * from './service/BusyService';
export * from './service/ContentService';
export * from './service/DebugInfoService';
export * from './service/EventService';
export * from './service/MappingService';
export * from './service/PageValueProvider';
export * from './service/PostURLService';
export * from './service/RenderModeService';
export * from './service/SnapshotService';
export * from './validation/ValidationRepository';
export * from './validation/constraints/DateDaysOfWeekConstraint';
export * from './validation/constraints/PreventSpecialCharAddressConstraint';
export * from './validation/constraints/PreventSpecialCharNameConstraint'
export * from './validation/directive/coField/FieldController';
export * from './validation/directive/coField/coFieldInputSemantic';
export * from './validation/directive/coField/coFieldInput';
export * from './validation/directive/coField/coFieldMessage';
export * from './validation/directive/coField/coFieldSemantic';
export * from './validation/directive/coField/coField';
export * from './validation/directive/coField/coFieldMessageSemantic';
export * from './validation/AsyncValidationResult';
export * from './validation/ValidationStatus';
export * from './directive/tracking/TrackingController';
export * from './global/tracking';
export * from './domain/MetaInfo';
export * from './domain/DayOfWeekType'
export * from './domain/TrackingProductLine';
export * from './directive/trackingAttributes/TrackingProductLineController';





import IAngularBootstrapConfig = angular.IAngularBootstrapConfig;


loadModuleCommerceCommon(angular);
loadModuleCommerceCommonValidationService(angular);
loadModuleCommerceCommonValidation(angular);
loadModuleCommerceCommonValidationSemantic(angular);

angular.element(document).ready(() => {

    let appNames = angular.element('meta[name="appName"]').toArray().map((element: Element) => {
        return angular.element(element).attr('content');
    });
    if (appNames.length === 0) {
        return;
    }

    angular.module('coApp', appNames);

    let bootApp = angular.element('meta[name="bootApp"]').attr('content');
    if (!bootApp || bootApp === 'false') {
        return;
    }

    let config: IAngularBootstrapConfig = {
        strictDi: true
    };
    angular.bootstrap(document, ['coApp'], config);
});
