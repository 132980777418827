import {MetaInfo, MetaType} from '../domain/MetaInfo';
import {AnnotatedConstructor, IMappingConfig, IMappingService, IMappingServiceProvider} from './IMappingService';
import {ILogger, ILoggerService} from './LoggerService';
import * as _ from "underscore";


export function mappingServiceProvider(): IMappingServiceProvider {

    var annotatedTypes: AnnotatedConstructor[] = [];
    var customTypes: CustomType[] = [];

    // exposed here to circumvent 'this' reference...
    function createMappingService(loggerService) {
        return new MappingService(loggerService, annotatedTypes, customTypes);
    }

    return {

        createMappingService: createMappingService,

        $get: ['loggerService', (loggerService: ILoggerService) => {
            return createMappingService(loggerService);
        }],

        addCustomType: function <T>(typeName: string,
                                    isType: (obj: T) => boolean,
                                    fromJson: (json: any) => T,
                                    toJson: (obj: T) => any): void {
            argsContract(arguments, 'string, function, function, function');
            customTypes.push(new CustomType(typeName, isType, fromJson, toJson));
        },

        addAnnotatedType: function (ctor: AnnotatedConstructor): void {
            argsContract(arguments, 'function & {META_INFO: (func | MetaInfo)}');
            annotatedTypes.push(ctor);
        }
    };

}


/**
 * This service maps between untyped objects that are created from json to TypeScript classes.
 * To use this service the ts class and type safe enums must define a static field of type MetaInfo.
 * Every class that should be mapped must be registered via the addType method.
 */
class MappingService implements IMappingService {

    static $inject = ['loggerService'];
    /**
     * this string is used to add and extract type information to and from the json graph.
     */
    private static TYPE_INFO_PROPERTY = '@type';

    /**
     * this string is the property name that is used to place or extract the name of a enum constant.
     */
    private static ENUM_NAME_PROPERTY = 'name';

    private logger: ILogger;

    private annotatedTypes: { [key: string]: CtorWithMetaInfo } = {};
    private customTypes: { [key: string]: CustomType } = {};

    constructor(loggerService: ILoggerService, annotatedTypes: AnnotatedConstructor[], customTypes: CustomType[]) {
        argsContract(arguments, '{}, {}, {}');
        this.logger = loggerService.create('commerce.common.MappingService');
        _.forEach(annotatedTypes, (ctor) => this.addAnnotatedType(ctor));
        _.forEach(customTypes, (customType) => this.addCustomType(customType));
    }

    private addAnnotatedType(ctor: AnnotatedConstructor): void {
        argsContract(arguments, 'function & {META_INFO: (func | MetaInfo)}');


        //replace the function for constructing the metaInfo with its result
        // the function my be needed to solve the resolution problem if the class that use the
        // commerce.common.valueMetaInfo() function before it is defined, this can happen if the packaging of the
        // js app don't define a order for the files.
        if (_.isFunction(ctor.META_INFO)) {
            ctor.META_INFO = (<() => MetaInfo>ctor.META_INFO)();
        }

        // okey now this function is in every case compatible
        var ctorWithMetaInfo: CtorWithMetaInfo = <any>ctor;

        var typeName = ctorWithMetaInfo.META_INFO.typeName;
        if (!typeName) {
            this.reportError('Type name is empty or null!', null, {
                typeName: typeName,
                newCtor: ctor,
                registeredCtors: this.annotatedTypes
            });
        }
        var existingCtor = this.annotatedTypes[typeName];
        if (existingCtor) {
            this.reportError('Type name already registered!', null, {
                typeName: typeName,
                existingCtor: existingCtor,
                newCtor: ctor,
                registeredCtors: this.annotatedTypes
            });
        }

        this.annotatedTypes[typeName] = ctorWithMetaInfo;
    }

    private addCustomType(customType: CustomType): void {
        argsContract(arguments, 'CustomType');
        this.customTypes[customType.typeName] = customType;
    }

    fromJson(json: any, config?: IMappingConfig): any {
        return this.fromJsonImpl(json, MappingOptions.fromObj(config));
    }


    fromJsonImpl(json: any, options: MappingOptions): any {

        if (options.failOnUndefined && _.isUndefined(json)) {
            this.reportError('Encountered "undefined" value!', options.path, {json: json});
        }
        if (json === null) {
            return null;
        }

        if (_.isArray(json)) {
            return _.map(json, (value: any, index: number) =>
                this.fromJsonImpl(value, options.withPathSegment('[' + index + ']'))
            );
        }


        if (_.isObject(json)) {
            var typeInfo: string = json[MappingService.TYPE_INFO_PROPERTY];
            if (!typeInfo) {
                // okay used as simple value object ... no type information ...
                var result = {};
                _.each(json, (value: any, key: string) =>
                    result[key] = this.fromJsonImpl(value, options.withPathSegment('.' + key))
                );
                return result;
            }

            return this.fromTypedJson(json, typeInfo, options);
        }

        return json;

    }


    private fromTypedJson(json: any, typeInfo: string, options: MappingOptions): any {

        var ctor: CtorWithMetaInfo = this.annotatedTypes[typeInfo];
        if (ctor) {
            if (ctor.META_INFO.typeMetaType === MetaType.ENUM) {
                return this.enumFromTypedJson(json, ctor, options);
            } else if (ctor.META_INFO.typeMetaType === MetaType.VALUE) {
                return this.valueFromTypedJson(json, ctor, options);
            }
            this.reportError('Unknown metaType: "' + ctor.META_INFO + '"',
                options.path, {
                    typeInfo: typeInfo,
                    metaType: ctor.META_INFO,
                    json: json,
                    knownAnnotatedConstructors: this.annotatedTypes
                }
            );
        }

        var customType: CustomType = this.customTypes[typeInfo];
        if (customType) {
            return customType.fromJson(json);
        }

        this.reportError('Unknown metaType: "' + typeInfo + '"', options.path, {
            typeInfo: typeInfo,
            json: json,
            knownAnnotatedConstructors: this.annotatedTypes
        });
    }

    private valueFromTypedJson(json: any, ctorWithMetaInfo: CtorWithMetaInfo, options: MappingOptions) {

        var metaInfo = ctorWithMetaInfo.META_INFO;
        var properties = {};
        _.each(
            metaInfo.propertyNamesAndContracts,
            (contract: string, name: string) => {
                var newOptions = options.withPathSegment('.' + name);
                var value = this.fromJsonImpl(json[name], newOptions);
                this.checkValueAgainstContract(value, contract, newOptions);
                properties[name] = value;
            }
        );

        var result = Object.create(ctorWithMetaInfo.prototype);
        _.extend(result, properties);
        return result;
    }

    private enumFromTypedJson(json: any, ctorWithMetaInfo: CtorWithMetaInfo, options: MappingOptions) {
        var enumTypeName = ctorWithMetaInfo.META_INFO.typeName;

        var enumName = json[MappingService.ENUM_NAME_PROPERTY];
        if (!enumName) {
            this.reportError('Invalid json for enum!', options.path, {
                json: json,
                enumConstructor: ctorWithMetaInfo,
                enumTypeName: enumTypeName
            });
        }

        var enumValue = ctorWithMetaInfo[enumName];
        if (!enumValue) {
            this.reportError('Invalid enum name!', options.path, {
                enumName: enumName,
                enumConstructor: ctorWithMetaInfo,
                enumTypeName: enumTypeName
            });
        }
        return enumValue;
    }


    private checkValueAgainstContract(value: any, contract: string, options: MappingOptions) {
        if (!options.validate) {
            return;
        }
        try {
            argsContract([value], contract);
        } catch (e) {
            if (e.name === 'ContractViolation') {
                this.reportError('Contract for value violated!', options.path, {
                    contract: contract,
                    value: value
                });
            }
            throw e;
        }
    }

    private reportError(message: string, debugPath: string, args?: any) {
        if (debugPath) {
            args = _.extend({path: debugPath}, args);
        }

        this.logger.error(message, args);

        var exceptionMessage = message + ' ';
        _.each(args, (value: any, name: string) => {
            exceptionMessage += (name + '=' + value + '; ');
        });

        throw new Error(exceptionMessage);
    }

    toJson(value: any, config?: IMappingConfig): any {
        return this.toJsonImpl(value, MappingOptions.fromObj(config));
    }

    toJsonImpl(value: any, options: MappingOptions): any {

        if (_.isUndefined(value)) {
            if (options.failOnUndefined) {
                this.reportError('Encountered undefined value!', options.path, {});
            } else {
                return value;
            }

        }
        if (_.isNull(value)) {
            return null;
        }
        if (_.isArray(value)) {
            return _.map(value, (element: any, index: number) => {
                return this.toJsonImpl(
                    element,
                    options.withPathSegment('[' + index + ']')
                );
            });
        }

        var customType: CustomType = _.find(this.customTypes, (ct: CustomType) => ct.isType(value));
        if (customType) {
            var obj = customType.toJson(value);
            obj[MappingService.TYPE_INFO_PROPERTY] = customType.typeName;
            return obj;
        }

        if (_.isObject(value)) {
            var metaInfo: MetaInfo = value.constructor && value.constructor.META_INFO;
            if (metaInfo) {
                if (!(metaInfo instanceof MetaInfo)) {
                    this.reportError('Invalid META_INFO field on ctor!', options.path, {
                        metaInfo: metaInfo,
                        value: value
                    });
                }

                if (metaInfo && metaInfo.typeMetaType === MetaType.ENUM) {
                    return this.enumToJson(value, metaInfo, options);
                }

                if (metaInfo && metaInfo.typeMetaType === MetaType.VALUE) {
                    return this.valueToJson(value, metaInfo, options);
                }
            } else {
                return this.objectToJson(value, options);
            }
        }

        return value;
    }

    private valueToJson(value: any, metaInfo: MetaInfo, options: MappingOptions): any {
        var obj = {};
        obj[MappingService.TYPE_INFO_PROPERTY] = metaInfo.typeName;
        _.each(metaInfo.propertyNamesAndContracts, (contract: string, key: string) => {
            var propertyValue = value[key];
            this.checkValueAgainstContract(propertyValue, contract, options.withPathSegment('.' + key));
            obj[key] = this.toJsonImpl(propertyValue, options)
        });
        return obj;
    }

    private enumToJson(value: any, metaInfo: MetaInfo, options: MappingOptions): any {
        var obj = {};
        var enumTypeName = metaInfo.typeName;
        var enumValue = value[MappingService.ENUM_NAME_PROPERTY];
        if (!enumValue) {
            this.reportError('Failed building json from enum, "' + MappingService.ENUM_NAME_PROPERTY +
                '" is undefined or null!',
                options.path, {
                    enumObject: value,
                    enumTypeName: enumTypeName
                });
        }
        obj[MappingService.TYPE_INFO_PROPERTY] = enumTypeName;
        obj[MappingService.ENUM_NAME_PROPERTY] = enumValue;
        return obj;
    }

    private objectToJson(value: any, options: MappingOptions): any {
        var obj = {};
        _.each(value, (value: any, key: string) =>
            obj[key] = this.toJsonImpl(value, options.withPathSegment('.' + key))
        );
        return obj;
    }
}

class MappingOptions {

    static fromObj(obj: IMappingConfig): MappingOptions {

        // use a default set on values and override these with the values supplied by the user ...
        var config = _.extend({
            path: 'ROOT',
            failOnUndefined: true,
            validate: true
        }, obj);
        // convert the parameter from the untrusted object to the right types ...
        return new MappingOptions(
            config.path.toString(),
            config.failOnUndefined === true,
            config.validate === true
        )
    }

    constructor(public path: string,
                public failOnUndefined: boolean,
                public validate: boolean) {
    }

    withPathSegment(pathAddition: string) {
        return new MappingOptions(this.path + pathAddition, this.failOnUndefined, this.validate);
    }
}


class CustomType {
    constructor(public typeName: string,
                public isType: (obj: any) => boolean,
                public fromJson: (json: any) => any,
                public toJson: (obj: any) => any) {
    }
}


interface CtorWithMetaInfo {
    prototype: any
    META_INFO: MetaInfo
}
