import {DebugUiToggleValue} from "../debugmodel/DebugUiToggleValue";
import {DebugUiToggleType} from "../debugmodel/DebugUiToggleType";
import {createLoggerService, ILogger} from "../service/LoggerService";
import {createUtils} from "../service/Utils";
import * as _ from "underscore";


export var featureToggles: DebugUiToggleValue[] = [];
export var featureToggleValues: { [featureToggleName: string]: any } = {};
var logger: ILogger = createLoggerService(createUtils()).create('DebugToggle');
const types = {
    'Boolean': DebugUiToggleType.BOOLEAN,
    'String': DebugUiToggleType.STRING
}

export function DebugToggle(metaProperty?: string) {
    return function (target: any, key: any, propertyDesciptor: PropertyDescriptor) {
        let metaData = Reflect.getMetadata('design:returntype', target, key).name;
        let debugUiToggleType: DebugUiToggleType = types[metaData];

        if (debugUiToggleType) {
            let toggle = new DebugUiToggleValue(metaProperty, debugUiToggleType);
            featureToggles.push(toggle);

            var oldFunction = propertyDesciptor.value;
            propertyDesciptor.value = function () {
                var replacedResult = featureToggleValues[toggle.name];
                var originalResult = oldFunction.call(this, arguments);
                if (_.isUndefined(replacedResult)) {
                    return originalResult;
                } else {
                    logger.debug('intercepted call', {toggle, originalResult, replacedResult});
                    return replacedResult;
                }
            }
        }
    }
}

export function getDebugUiToggle(toggleName: string) {
    return featureToggleValues[toggleName];
}

export function setDebugUiToggle(toggleName: string, value: any) {
    featureToggleValues[toggleName] = value;
}

export function deleteDebugUiToggle(toggleName: string) {
    delete featureToggleValues[toggleName];
}
