import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class EmailConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:EmailConstraint', {
        key: 'string',
        pattern: 'string'
    });

    public constructor(public key: string, private pattern: string) {
        this.pattern = pattern;
    }

    public applies(value: any): boolean {
        if (value === null || value == undefined || value === "") {
            //required validation is handled elsewhere...
            return true;
        }
        return new RegExp(this.pattern).test(value);
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
