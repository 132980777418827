/**
 * converts a number to a string with a
 * fixed decimal size. Only used to display
 * prices in the UI.
 */
export class DisplayMoney {

    euros: string;
    cents: string;
    currency = "€";

    constructor(public amount: number, currency: string = "€") {
        if (amount == undefined) {
            return;
        }
        var money = amount.toFixed(2).toString();
        var values: string[] = money.split('.');
        this.euros = values[0];
        this.cents = values[1];
        this.currency = currency;
    }
}
