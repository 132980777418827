/**
 * Directive for scrolling to a certain point of the page.
 *
 * @param target CSS Selector of the scroll's target element
 * @param [offset] In pixels, how much should the scrolling be shorter
 * @param [offsetSelector] CSS Selector of an element whose height is going to be used as offset
 * @param [delay] Number of milliseconds to wait for scroll to start
 * @param [shouldNotPreventDefault] If the default click event handler should be triggered
 */
import {IAugmentedJQuery, IDirective} from "angular";

export function coScrollTo(): IDirective {
    return {
        scope: {
            target: '=coScrollToTarget',
            offset: '=coScrollToOffset',
            offsetSelector: '@coScrollToOffsetSelector',
            delay: '@coScrollToDelay',
            duration: '@coScrollToDuration',
            shouldNotPreventDefault: '=coScrollToShouldNotPreventDefault'
        },
        link: (scope: any, element: IAugmentedJQuery): void => {
            element.on('click', (e: JQuery.Event) => {

                if (!scope.shouldNotPreventDefault) {
                    e.preventDefault();
                }

                scope.delay ? setTimeout(scroll, scope.delay) : scroll();

                function scroll(): void {
                    var $target: JQuery = $(scope.target);
                    if ($target.length === 0) {
                        return;
                    }

                    var targetHeight: number = $target.offset().top;

                    if (scope.offsetSelector) {
                        targetHeight = targetHeight - $(scope.offsetSelector).height();
                    }
                    if (scope.offset) {
                        targetHeight = targetHeight - scope.offset;
                    }

                    var duration: number = scope.duration ? parseInt(scope.duration) : 1000;
                    $('body,html').animate({scrollTop: targetHeight}, duration);
                }
            });
        }
    };
}
