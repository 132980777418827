/**
 * This directive removes any directive that would appear as a child directive.
 * It is used if you need to remove a child directive even before the HTML has been compiled (way before linking
 * phase). Because they'll be removed from the template that should be linked, the child directives will never
 * be considered.
 *
 * The directive accepts a comma-separated list of directive names that should be removed, e.g. "data-co-slick,data-co-modal-info".
 */
import * as angular from "angular";
import {IDirective} from "angular";


export function coRemoveLowerAttributeDirectives(): IDirective {
    return {
        priority: -1,
        compile: function (element: ng.IAugmentedJQuery, attributes: any) {
            var attributesToRemove = attributes.coRemoveLowerAttributeDirectives.split(",");

            angular.forEach(attributesToRemove, function (attribute) {
                element.find("[" + attribute + "]").removeAttr(attribute);
            });

            return {}
        }
    };
}
