import {IWindowService} from "angular";
import {ITrackingAttributesService} from "./ITrackingAttributesService";

export class TrackingAttributesService implements ITrackingAttributesService {

    static $inject = ['$window'];
    private debug: boolean;
    private pageArea: string;

    constructor(private $window: IWindowService) {
    }

    $onInit(): void{
        let debugMetaElementContent = this.getMetaElementContent('commerce_debug_modes');
        this.debug = debugMetaElementContent && debugMetaElementContent.indexOf('tracking') !== -1;

        this.pageArea = this.getMetaElementContent('pagearea');

        if(!this.pageArea){
            console.error("Could not find <meta name='pagearea' content='...'/> - no tracking attributes will be rendered!");
        }
    }

    getApplication(): string {
        return "e-shop";
    }

    getPageArea(): string {
        return this.pageArea;
    }

    isDebuggingEnabled(): boolean {
        return this.debug;
    }

    private getMetaElementContent(name: string): string {
        let metaElement = this.$window.document.querySelector(`meta[name=${name}]`);
        return  (metaElement && metaElement['content']) || null;

    }
}
