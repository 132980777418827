import {createLoggerService, ILogger} from '../service/LoggerService';
import {createUtils} from '../service/Utils';
import * as _ from "underscore";


/**
 * This class is used as a parameter for tracking callbacks.
 */
export class TrackingData {
    constructor(public pageValue: {}) {
    }
}

/**
 * Class for global instance to test the class and not a global object.
 */
export class Tracking {

    private _logger: ILogger = null;

    /**
     * This function is used to get the current page value.
     * This must be a function to evaluate the pageValueSource only if there are any 'prepare' scripts, so no that the
     * pageValue can be lazy for testing.
     */
    private _pageValueSource: () => {} = null;

    /**
     * This variable is used to store the tracking function that should be invoked or null if the function should be
     * invoked immediate.
     */
    private _prepareTrackingFunctions: { (data: TrackingData): void; } [] = [];


    /**
     * This function is used to add a function to prepare the tracking data. This function is invoked when all
     * tracking data is available or instantly if the tracking data is already available.
     */
    prepare(fn: (data: TrackingData) => void) {
        if (this._prepareTrackingFunctions !== null) {
            this._prepareTrackingFunctions.push(fn);
        } else {
            this.invokeTrackingFunction(fn);
        }

    }

    /**
     * This function is used to setup the function that returns the pageValue of this site as part of the tracking data.
     * The first invocation of this function triggers already added prepare functions.
     */
    setupPageValueSource(pageValueSource: () => {}) {
        this._pageValueSource = pageValueSource;
        this.tryDoInit()
    }

    /**
     * This function is used check if setup is completed and then run the stored _prepareTrackingFunctions.
     */
    private tryDoInit() {
        // check if all tracking data is set ...
        // at the moment only the pageValue is used...
        if (this.isSetupComplete() && this._prepareTrackingFunctions !== null) {
            _.forEach(this._prepareTrackingFunctions, (fn) => this.invokeTrackingFunction(fn));
            this._prepareTrackingFunctions = null;
        }
    }

    /**
     * Checks if all needed setup values are set. At the moment only pageValueSource is checked possible there may be
     * other values.
     */
    private isSetupComplete(): boolean {
        return this._pageValueSource !== null;
    }

    /**
     * This function is used to invoke a tracking function within its own event callback and surrounded with try/catch.
     */
    private invokeTrackingFunction(fn) {
        // invoke the function in own interpreter run and within try catch block ... max security ;)
        // check if this is fine for GTM event ordering ...
        setTimeout(
            () => {
                var data = new TrackingData(this._pageValueSource.call(null));
                try {
                    fn.call(null, data);
                } catch (error) {
                    this.logger().warn('Error invoking tracking function!', {fn: fn, error: error})
                }
            },
            0
        );
    }


    private logger() {
        if (!this._logger) {
            // create the logger lazy so we are sure we are independent of the order of js packaging
            this._logger = createLoggerService(createUtils()).create('tracking');
        }
        return this._logger;
    }

}

/**
 * global instance
 * @type {commerce.common.Tracking}
 */
export var tracking = new Tracking();
