import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class PreventSpecialCharAddressConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:PreventSpecialCharAddressConstraint', {
        key: 'string',
        pattern: 'string'
    });

    public constructor(public key: string, private pattern: string) {
    }

    public applies(value: any): boolean {
        if (!value) {
            /*fields that are not initalized or empty shall not cause a validation error*/
            return true;
        }

        return this.pattern.split("").filter(function (forbiddenChar) {
            return value.toString().indexOf(forbiddenChar) !== -1;
        }).length == 0;
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
