import {valueMetaInfo} from '../domain/MetaInfo';

/*
* Class decorator which sets the META_INFO static property for the mapping service
* and expects an already provided metaInfoObject property.
* */
export function ValueMetaInfo(metaValueInfo: string) {
    return function (target: any) {
        target['metaInfoObject'] = target['metaInfoObject'] || {};
        target['META_INFO'] = valueMetaInfo(metaValueInfo, target['metaInfoObject']);
    }
}
