import {Call, ConstantCall, NavigationEvent} from '../domain/Call';
import {ILocationService} from "angular";

export interface IRelativeLocationService {
    pushState(targetURL: string): void;

    pushCallToState(targetCall: Call<any>): void
}

export class RelativeLocationService implements IRelativeLocationService {
    static $inject = ['$location'];

    constructor(private $location: ILocationService) {
    }

    pushState(targetURL: string) {
        var baseRelativeTabUrl = targetURL;
        baseRelativeTabUrl = baseRelativeTabUrl
            .replace(/^(https?:)?\/\/[^\/]*/, '')
            .replace($('head').find('base').attr('href'), '');

        /*
         OSD-441: Workaround to enable switching to the default tab in hardware catalog with query parameters
         e.g. from /tablets?tarif=o2-blue-all-in-m to /?tarif=o2-blue-all-in-m
         without the leading slash (/?) the url won't get updated in the browser
         */
        if (baseRelativeTabUrl.charAt(0) === '?') {
            baseRelativeTabUrl = '/' + baseRelativeTabUrl;
        }

        this.$location.url(baseRelativeTabUrl).replace();
    }

    pushCallToState(targetCall: Call<any>): void {
        if (targetCall instanceof ConstantCall) {
            if (targetCall.constantPayload instanceof NavigationEvent) {
                this.pushState(targetCall.constantPayload.link.uri);
                return;
            }
        }
        throw new Error('can only push ConstantCall containing NavigationEvent');
    }
}
