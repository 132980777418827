import {IContentService} from '../../service/IContentService';
import coResolveContentTemplate from "./coResolveContentTemplate.html";


coResolveContent.$inject = <any>['contentService', '$sce'];

export function coResolveContent(contentService: IContentService, $sce: ng.ISCEService): ng.IDirective {
    return {
        scope: {
            content: '=coResolveContent',
            onContentResolved: '&'
        },
        replace: true,
        transclude: true,
        template: coResolveContentTemplate,
        link: (scope:any, element, attrs) => {
            scope.$watch('content', () => {

                var content = scope['content'];
                if (content) {
                    var contentPromise:ng.IPromise<string> = contentService.resolve(content);
                    contentPromise.then((markup:string) => {
                        scope['markup'] = $sce.trustAsHtml(markup);
                        setTimeout(()=> {
                            scope.$digest();
                            scope.onContentResolved();
                        }, 0);
                    })
                }
            })
        }
    };
}
