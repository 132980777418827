import {IDebugUiToggleService} from "../IDebugUiToggleService";
import {DebugUiToggleValue} from "../DebugUiToggleValue";
import {Injectables} from "../../decorators/Injectables";
import {DebugUiToggleType} from "../DebugUiToggleType";

@Injectables(IDebugUiToggleService)
export class DebugUiController {
    constructor(private debugUiToggleService: IDebugUiToggleService) {
    }

    private uiMinimized: boolean;

    get enabled(): boolean {
        return this.debugUiToggleService.enabled;
    }

    get toggles(): DebugUiToggleValue[] {
        return this.debugUiToggleService.toggles;
    }

    get booleanToggles(): DebugUiToggleValue[] {
        return this.toggles.filter((t) => {
            return t.type == DebugUiToggleType.BOOLEAN;
        })
    }

    get stringToggles(): DebugUiToggleValue[] {
        return this.toggles.filter((t) => {
            return t.type == DebugUiToggleType.STRING;
        })
    }

    toggleAllElements(): void {
        if (this.allElementsActive()) {
            this.debugUiToggleService.deactivateAllElements();
        } else {
            this.debugUiToggleService.activateAllElements();
        }
    }

    allElementsActive(): boolean {
        return this.debugUiToggleService.allElementsActive();
    }

    isUiMinimized(): boolean {
        return this.uiMinimized;
    }

    toggleUiMinimized(): void {
        this.uiMinimized = !this.uiMinimized;
    }


}