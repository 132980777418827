export interface ITrackingAttributesService {
    getApplication(): string;

    getPageArea(): string;

    isDebuggingEnabled(): boolean;
}

export class ITrackingAttributesService {
};
