export interface IUtilsService {
    regExpFromSimplePattern(patternString: string): RegExp

    getLocalStorage(): Storage
}


/**
 * Function to access local storage if available defined here to be accessible by the log package.
 * @return {null | Storage} The local storage or null if no local storage is available.
 */
function getLocalStorage(): Storage {
    if (typeof(Storage) !== 'undefined') {
        try {
            if (window.localStorage) {
                window.localStorage.setItem('test', 'test');
                window.localStorage.removeItem('test');
                return window.localStorage;
            }
        } catch (e) {
        }
    }
    return null;
}


/**
 * Creates a regular expression that match a simple string pattern that contains '*' for any string.
 * The string can only contain word characters and '.'.
 * @return {RegExp}
 */
function regExpFromSimplePattern(patternString) {
    argsContract(arguments, 'str');
    if (!/^[\w.:/*]*$/.test(patternString)) {
        throw new Error("Failed compiling '" + patternString + "' illegal character found!");
    }
    return new RegExp(patternString.replace(/\./g, '\\.').replace(/\*/g, '.*'));
}

export function createUtils(): IUtilsService {
    return {
        getLocalStorage: getLocalStorage,
        regExpFromSimplePattern: regExpFromSimplePattern
    }
}
