import {ILoggerService} from '../service/LoggerService';
import {IPageValueProvider} from '../service/IPageValueProvider';
import {IValidationRepository} from './IValidationRepository';

export interface IValidationRepositoryProvider extends ng.IServiceProvider {
    create(loggerService: ILoggerService, pageValueProvider: IPageValueProvider<any>): IValidationRepository;

    addPageValueName(pageValueName: string): void;

    addDefaultPageValueName(): void;
}

export class IValidationRepositoryProvider {
    static injectableName: string = 'validationRepositoryProvider';
}
