import {valueMetaInfo, MetaInfo} from './MetaInfo';

/**
 * Generic Error that can be the return value of a failed request.
 */
export class GenericRequestError {

    static META_INFO = () => valueMetaInfo('infrastructure:GenericRequestError', {
        messages: '[string]',
    });

    messages: string[];
}
