import {IDirective} from "angular";
import {TrackingProductLineController} from "./TrackingProductLineController";

export function coTrackingProductLines(): IDirective {
    return {
        controller: TrackingProductLineController,
        // use here a long and very specific controller name ...
        // this directive has no isolated scope, so the controller will be bound to the parent scope...
        controllerAs: "$trackingProductLineController",
        bindToController: {coTrackingProductLines: "<coTrackingProductLines"},
    };
}