import {IContentService} from './IContentService';
import {ICallService} from './ICallService';
import {Content, LazyContent, ResolvedContent} from '../domain/Content';


export class ContentService implements IContentService {

    static $inject = ['callService', '$q'];

    constructor(private callService: ICallService, private $q: ng.IQService) {
    }

    resolve(content: Content): ng.IPromise<string> {
        argsContract(arguments, 'Content');

        if (content instanceof ResolvedContent) {
            return this.$q.when((<ResolvedContent>content).content);
        } else if (content instanceof LazyContent) {
            return this.callService.handleCall((<LazyContent>content).callForContent);
        } else {
            throw new Error("Unknown type of content.");
        }
    }
}
