import {IAdaptiveService} from '../../service/IAdaptiveService';
import {IDirective} from "angular";


// Inject the ng-if directive
coAdaptiveIf.$inject = ['adaptiveService', 'ngIfDirective'];

/**
 * Directive to remove/add DOM elements depending on the allowed breakpint
 *
 * IMPORTANT: If you are using ng-if on the same element, this directive will not work - ng-if has precedence.
 * For such cases, use the adaptive service and its breakpoints checking API.
 */
export function coAdaptiveIf(adaptiveService: IAdaptiveService,
                             ngIfDirective: IDirective): IDirective {
    // Get the actual directive object
    var ngIf = ngIfDirective[0];

    return {
        // Set defaults as they already are on the ng-if directive
        transclude: ngIf.transclude,
        priority: ngIf.priority - 1, // ng-if has precedence
        terminal: ngIf.terminal,
        restrict: ngIf.restrict,
        link: function ($scope, $element, attributes: any) {
            // Check if there already is an ng-if directive on the element
            var initialNgIf = attributes.ngIf,
                ifEvaluator;
            // If there is a ng-if directive on the element, this directive does nothing
            if (initialNgIf) {
                ifEvaluator = function () {
                    return $scope.$eval(initialNgIf);
                }
            } else {
                // If there is no ng-if directive, continue
                ifEvaluator = function () {
                    var breakpoints = attributes.coAdaptiveIf.split(',');

                    if (breakpoints.length === 0) {
                        return true;
                    }
                    else {
                        return adaptiveService.checkBreakpoints(breakpoints);
                    }
                }
            }

            attributes.ngIf = ifEvaluator;
            ngIf.link.apply(ngIf, arguments);
        }
    }
}
