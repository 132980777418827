import {AbstractCall, ConstantCall, NavigationEvent} from '../../domain/Call';
import {ICallService} from '../../service/ICallService';
import {IDirective} from "angular";


coCallAction.$inject = ['callService'];

export function coCallAction(callService: ICallService): IDirective {
    return {
        scope: {
            callAction: '=coCallAction',
            callActionPreventDefault: '=coCallActionPreventDefault',
            callActionStopPropagation: '=coCallActionStopPropagation'
        },

        link: function (scope: any, element: any) {

            var callAction = scope.callAction;
            var paramPreventDefault: boolean = !!scope.callActionPreventDefault;
            var paramStopPropagation: boolean = !!scope.callActionStopPropagation;

            if (callAction == null) {
                throw new Error('callAction is null')
            }
            var targetElement: HTMLElement = element.get(0);
            var tagName = targetElement.nodeName;

            if (tagName.toUpperCase() === 'A') {
                var anchorElement: HTMLAnchorElement = <HTMLAnchorElement>targetElement;

                if (callAction instanceof ConstantCall) {
                    try {
                        var constantCall: ConstantCall<NavigationEvent> = <ConstantCall<NavigationEvent>>callAction;
                        var linkUrl = constantCall.constantPayload.link.uri;

                        anchorElement.href = linkUrl;
                    } catch (e) {
                        throw new Error('error while handling NavigationEvent:' + e)
                    }
                } else if (callAction instanceof AbstractCall) {
                    element.on('click', function (event: ng.IAngularEvent) {
                        event.stopPropagation();
                        event.preventDefault();

                        callService.handleCall(callAction);
                    });
                } else {
                    throw new Error('unimplemented Call type requested');
                }
            } else {
                element.on('click', function (event: ng.IAngularEvent) {
                    if (paramPreventDefault) {
                        event.preventDefault();
                    }

                    if (paramStopPropagation) {
                        event.stopPropagation();
                    }

                    callService.handleCall(callAction);
                });
            }
        }
    };
}
