import * as _ from "underscore";

import {DebugUiToggleValue} from "./DebugUiToggleValue";
import {IDebugUiToggleService} from "./IDebugUiToggleService";
import {
    deleteDebugUiToggle,
    featureToggles,
    featureToggleValues,
    getDebugUiToggle,
    setDebugUiToggle
} from "../decorators/DebugToggle";
import {createUtils} from "../service/Utils";

export class DebugUiToggleService implements IDebugUiToggleService {

    static $inject: string[] = ['$location', '$window'];

    localStorage: Storage = createUtils().getLocalStorage();
    private _enabled: boolean;

    defaultValues: { [toggleName: string]: any } = {
        'BOOLEAN': false,
        'STRING': 'Luminous Elucidator'
    };

    constructor(private $location: ng.ILocationService, private $window: ng.IWindowService) {
    }

    $onInit(): void {
        // test if 'debugUi' is in the query parameters
        this._enabled = /debugUi/i.test(this.$window.location.search);

        if (this.enabled) {
            this.readToggles();
        }
    }

    get toggles(): DebugUiToggleValue[] {
        return featureToggles;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    setActive(toggle: DebugUiToggleValue, active: boolean) {
        if (!active) {
            deleteDebugUiToggle(toggle.name);
        } else {
            let value = this.defaultValues[toggle.type.name];
            setDebugUiToggle(toggle.name, value)
            this.storeToggles();
        }
        this.storeToggles();
    }

    activateAllElements(): void {
        _.each(this.toggles, (t) => {
            this.setActive(t, true);
        });
    }

    deactivateAllElements(): void {
        _.each(this.toggles, (t) => {
            this.setActive(t, false);
        });
    }

    allElementsActive(): boolean {
        return _.filter(this.toggles, (t) => {
            return !this.isActive(t);
        }).length == 0;
    }

    isActive(toggle: DebugUiToggleValue): boolean {
        return !_.isUndefined(getDebugUiToggle(toggle.name));
    }

    setValue(toggle: DebugUiToggleValue, value: any) {
        setDebugUiToggle(toggle.name, value);
        this.storeToggles();
    }

    getValue(toggle: DebugUiToggleValue): any {
        return getDebugUiToggle(toggle.name);
    }

    private readToggles(): void {
        let storedToggles: { [featureToggleName: string]: any } = JSON.parse(this.localStorage.getItem('toggles'));

        if (!_.isNull(storedToggles)) {
            featureToggles
                .filter(t => !_.isUndefined(t) && !_.isNull(t))
                .forEach(t => {
                    featureToggleValues[t.name] = storedToggles[t.name];
                });
        }

    }

    private storeToggles(): void {
        this.localStorage.setItem('toggles', JSON.stringify(featureToggleValues));
    }


}
