import {coFieldMessageSemantic} from "./validation/directive/coField/coFieldMessageSemantic";
import {coFieldInputSemantic} from "./validation/directive/coField/coFieldInputSemantic";
import {coFieldSemantic} from "./validation/directive/coField/coFieldSemantic";

export function loadModuleCommerceCommonValidationSemantic(angular) {
    angular.module('commerce_common_validation_semantic', ['commerce_common', 'commerce_common_validation_services'])
        .directive('coField', coFieldSemantic)
        .directive('coFieldInput', coFieldInputSemantic)
        .directive('coFieldMessage', coFieldMessageSemantic)
    ;
}
