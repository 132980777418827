export function classToFactory(ctor: any): any {
    var dependencies = ctor.$inject || [];
    var factoryFunction = function () {
        var instance = Object.create(ctor.prototype);
        ctor.apply(instance, arguments);

        var initFunction = instance['$onInit'];
        if (initFunction) {
            initFunction.apply(instance);
        }

        return instance;
    };
    return [].concat(dependencies).concat([factoryFunction]);
}
