import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import * as _ from "underscore";
import {IAugmentedJQuery} from "angular";


export class ValidDateConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:ValidDateConstraint', {
        key: 'string'
    });

    constructor(public key: string) {
    }

    public applies(value: any): boolean {
        // these cases are handled by the required validator ...
        if (_.isUndefined(value) || _.isNull(value) || value === '') {
            return true;
        }
        return _.isDate(value);
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
