import {IAugmentedJQuery} from "angular";

export interface IConstraint {

    applies(value: any): boolean;

    modifyInputField(input: IAugmentedJQuery): void;

    key: string;
}

export class IConstraint {
}
