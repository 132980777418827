import {Content} from '../domain/Content';
import {IPromise} from "angular";

export interface IContentService {

    /**
     * Resolve the string for the given content, i.e. fetch content from server if lazy, otherwise just return.
     */
    resolve(content: Content): IPromise<string>
}

export class IContentService {
}
