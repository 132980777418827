import * as _ from "underscore";
import {IAttributes, IAugmentedJQuery, IDirective} from "angular";


coIncludeHtml.$inject = ['$sce'];

export function coIncludeHtml($sce: ng.ISCEService): IDirective {

    return {
        scope: {
            includeHtmlUrl: '=coIncludeHtmlUrl',
            coLoadCallback: '&'
        },
        replace: true,
        link: function (scope: any, element: IAugmentedJQuery, attrs: IAttributes) {

            function trustedUrl() {

                var includeHtmlUrl = scope.includeHtmlUrl;
                return _.isString(includeHtmlUrl) ? includeHtmlUrl : $sce.getTrustedUrl(includeHtmlUrl);
            }

            function loadElementContent(element: IAugmentedJQuery, url: string, scope: any) {
                element.load(url, (response, status, xhr) => {
                    element.html(response);
                    if (scope.coLoadCallback != undefined) {
                        scope.coLoadCallback({responseContent: response});
                    }
                });
            }

            // if trustedUrl returns null, nothing will happen
            scope.$watch(trustedUrl, (newUrl) => {
                loadElementContent(element, newUrl, scope);
            });
        }
    }
}
