import * as angular from "angular";
import * as $ from "jquery";


import {createLoggerService, ILogger} from '../service/LoggerService';
import {createUtils} from '../service/Utils';

/**
 * This logger is initialised lazy by calling the #logger() function.
 */
var _logger: ILogger = null;

// exported snapshot object ...
export var snapshot = {
    /**
     * This function is used to boot a pre-rendered page, this is done by the following steps:
     * 1.) find the script tag that contains the original body source
     * 2.) take the content of the script tag
     * 3.) unescape the script tags in the body source
     * 4.) parse the unescaped body source
     * 5.) extract all js script tags
     * 6.) remove the body of the document
     * 7.) insert the original body source
     * 8.) boot angular
     * 9.) load and execute the java script tags that has been extracted before in 5.)
     * 10.) celebrate!
     */
    boot() {
        // registering on dom ready boot script ...
        logger().info('original body -- searching');

        // find the script tag that contains the body
        var $bodyContentScript = $('script#originalBodyContent');
        if ($bodyContentScript.length === 1) {
            logger().info('original body -- found');
        } else if ($bodyContentScript.length === 0) {
            error('original body -- not found');
        } else {
            error('original body -- more than one found');
        }

        logger().info('unescaping original body content');
        // the origianl body content is the text in the script tag ...
        var escapedBodySource = $bodyContentScript.text();

        if (!escapedBodySource) {
            error('unescaping original body content -- no content found!')
        }

        // all script tags are escaped ... we must first unescaped them ...
        var bodySource = escapedBodySource.replace(/<([\/\s]*)__script__([^>]*)>/ig, '<$1script$2>');

        // parse the body so we can modify its content ...
        var newBody = $(bodySource);


        // If we let jquery replace the body with js script elements, these elements are loaded async and out of order
        // so we extract the script tags and load one script after the other to ensure the right order ...
        var scriptsToLoad = extractJsScriptElements(newBody);

        // first replace the body and boot angular ... all other body scripts are evaluated afterwards ...
        // if an error is thrown angular is still up and running ...
        replaceBodyAndBootAngular(newBody, 'coApp');

        // reverse the order of the array so we can use Array#pop()
        scriptsToLoad.reverse();

        // start adding script after script and at the end replace the remaining body elements ...
        setTimeout(
            () => {
                logger().info("Begin loading scripts in body tag ...");
                loadNextScript(
                    scriptsToLoad,
                    () => onFinishBoot()
                );

            },
            0
        );

    }
};

/**
 * Remove all script tags that are of type javascript or have no type and return the removed elements.
 */
function extractJsScriptElements(newBody): JQuery[] {
    var jsScriptElements = [];
    newBody.find("script").each((idx, scriptElement) => {
        var script = $(scriptElement);
        var type = script.attr("type")

        // only process javascript types
        if (type && !/.*javascript.*/.test(type.toLowerCase())) {
            return;
        }

        script.remove();
        jsScriptElements.push(script);
    });

    return jsScriptElements;
}

/**
 * Replace the body content with the newBody content and starts the supplied angular applicaiton.
 *
 * @param newBody the original body without javascript tags.
 * @param appName the name of the angular application that should be booted.
 */
function replaceBodyAndBootAngular(newBody: JQuery, appName: string) {
    logger().info('replacing body');
    //var bodyHtml = newBody[0].outerHTML;
    //$('body').html(bodyHtml);
    $('body').children().remove();
    $('body').append(newBody);

    logger().info('booting angular -- app: ' + appName);
    if (!angular) {
        error('booting angular -- \'angular\' not found');
    }
    angular.bootstrap(document, [appName]);
    logger().info('booting angular -- done');
}


/**
 * Load or execute the first script tag in the array and remove it.
 * If it is a reference load it via ajax and execute it, if it is a inline script execute it immediately.
 * After the execution of the script the function is invoked recursively.
 * If the array is empty execute the onDone callback.
 */
function loadNextScript(scriptsToLoad: JQuery[], onDone: () => void) {

    // check if we have loaded all scripts ...
    if (scriptsToLoad.length === 0) {
        // call the onDone callback ...
        onDone();
        return;
    }

    // remove the top script from the array ...
    var script = scriptsToLoad.pop();
    var src = script.attr('src');

    // check if it is a script ref or a inline script
    if (src) {

        // if its a ref load it via jquery and after the ajax call returns load the next ...
        logger().info('loading script: ' + src);
        $.ajax({
            async: true,
            url: src,
            dataType: "script",
            success: (data) => {
                logger().info('finished loading ... loading next');
                loadNextScript(scriptsToLoad, onDone);
            }
        });
    } else {

        // if its a inline script ... put it into the page directly ... and load the next ...
        logger().info('appending inline script: ' + script.text());
        $('head').append(script);
        loadNextScript(scriptsToLoad, onDone);
    }
}

/**
 * get a logger or construct one on the fly...
 */
function logger(): ILogger {
    if (!_logger) {
        // create the logger lazy so we are sure we are independent of the order of js packaging
        _logger = createLoggerService(createUtils()).create('snapshot');
    }
    return _logger;
}

/**
 * Log a error message and throw an error.
 */
function error(msg): void {
    logger().error(msg);
    throw new Error('SNAPSHOT -- ' + msg);
}

function onFinishBoot(): void {

    logger().info("Done loading scrip   ts in body tag")

    logger().info('sending event tel.app.init to init bootstrap');
    $(document).trigger('tel.app.init');

    /**
     * TODO (poslekk) - Temporary initialisation of CMS functions
     * Should be removed once snapshotting is compatible with bindings of non-live events.
     */
    var anyWindow = <any>window;

    if ((anyWindow).$.fn.responsiveTabs) {
        (<any>$('.nav.nav-tabs')).responsiveTabs();
    }

    if ((anyWindow).webapp) {
        if ((anyWindow).webapp.cmpModal) {
            (anyWindow).webapp.cmpModal.init();
        }
        if ((anyWindow).webapp.cmpTopOffer) {
            (anyWindow).webapp.cmpTopOffer.init();
        }
    }

}
