import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import * as _ from "underscore";
import {IAugmentedJQuery} from "angular";


export class NumericConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:NumericConstraint', {
        key: 'string'
    });

    constructor(public key: string) {
    }

    public applies(value: any): boolean {
        if (value === null || _.isUndefined(value) || value.length === 0) {
            return true;
        }
        return /^\d+$/.test(value.toString());
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
