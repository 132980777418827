import {IDocumentService} from "angular";

export class MockSettingsService {

    static $inject = ['$document'];

    private mockSettingsString;

    constructor($document: IDocumentService) {
        var metaElement = $document.find('meta[name=mock]');
        if (metaElement.length !== 0) {
            this.mockSettingsString = metaElement.attr("content");
        }
    }

    getMockSettings(): string {
        return this.mockSettingsString;
    }

}
