import {AsyncValidationResult} from '../../AsyncValidationResult';
import {ValidationStatus} from '../../ValidationStatus';
import {CallConstraint} from '../../constraints/CallConstraint';
import {FieldConfiguration} from '../../FieldConfiguration';
import {ILogger, ILoggerService} from '../../../service/LoggerService';
import {ICallService} from '../../../service/ICallService';
import {Injectables} from '../../../decorators/Injectables';
import {IValidationRepository} from '../../IValidationRepository';
import * as _ from "underscore";
import {IAugmentedJQuery, IFormController, INgModelController, IPromise, IQService} from "angular";
import {IConstraint} from "../../..";


/**
 * This controller is used to connect multiple directives of the co-field infrastructure.
 * The controller is initialised by the co-field directive.
 * The co-field-input directive is used to mark a element that contains the model of this field.
 * The co-field-popover directive is used to show a popover with the error messages of this field.
 */
@Injectables(IValidationRepository, ILoggerService, ICallService, '$q')
export class FieldController {
    // auto bound by directive
    public fieldName: string;

    //created in ctor
    private logger: ILogger;

    // set by connecting to FieldInput directive
    public ngModel: INgModelController;
    public inputElement: IAugmentedJQuery;

    // set after initialisation ...
    public parent: FieldController;
    public form: IFormController;

    constructor(private validationRepository: IValidationRepository,
                loggerService: ILoggerService,
                public callService: ICallService,
                private $q: IQService) {
        this.logger = loggerService.create('commerce.common.FieldController');
    }


    /**
     * This method is used by the directive that declare this controller to initialise it with infrastructure from the parent elements.
     * @param parent The parent controller which might be undefined. This is used for multifield validation. Can be null.
     * @param form The form in which this field is located. This is mainly needed for checking of the submitted state. Can be null.
     */
    init(parent: FieldController, form: IFormController): void {
        argsContract(arguments, 'FieldController | null, {} | null');
        this.checkFieldName();
        this.parent = parent;
        this.form = form;
    }


    private checkFieldName() {
        if (!this.fieldName) {
            throw new Error('No field name set by binding!');
        }
    }


    /**
     * This method is used to connect a element which contains the ng-model to this controller.
     * This method is called by co-field-input.
     *
     * @param ngModel the INgModelController object for the element.
     * @param inputElement the element which the model, this is typically a input element but can be anythi
     * In the case of a multifield input this can be a div.
     */
    connectCoFieldInput(ngModel: INgModelController, inputElement: IAugmentedJQuery): void {
        argsContract(arguments, '{}, {}');
        this.checkFieldName();
        if (this.ngModel) {
            this.logger.error('This FieldController has already a ngModel instance,' +
                ' are there more than one connected co-field-inputs in this co-field?', {
                    fieldController: this,
                    ngModel: ngModel,
                    inputElement: inputElement
                }
            );
            throw new Error('This FieldController has already a ngModel instance, ' +
                'are there more than one connected co-field-inputs in this co-field?');
        }
        this.ngModel = ngModel;
        this.inputElement = inputElement;

        /**
         *  if the field is invisible the viewValue is emptied and no validators are added.
         *  This prevents data from loaded customers or Chrome-plugins for autofilling forms to be sent back
         *  to the server.
         */
        if(this.isFieldVisible()){
            let validators: Validator<boolean>[] = this.getValidators();
            let asyncValidators: Validator<IPromise<AsyncValidationResult>>[] = this.getAsyncValidators();
            let configuration: FieldConfiguration = this.getConfiguration();

            validators.forEach((validator: Validator<boolean>) => {
                ngModel.$validators[validator.key] = validator.validationFunction
            });

            asyncValidators.forEach((validator: Validator<IPromise<AsyncValidationResult>>) => {
                ngModel.$asyncValidators[validator.key] = validator.validationFunction;
            });

            if (configuration && configuration.allowsValidation()){
                configuration.constraints.forEach((constraint: IConstraint) => {
                    constraint.modifyInputField(inputElement);
                });
            }

        } else {
            ngModel.$setViewValue(null);
        }
    }


    /**
     * @returns {boolean} Returns true if there is a validation configuration for this field.
     */
    allowsValidation(): boolean {
        var fieldConstraint: FieldConfiguration = this.getConfiguration();
        return !_.isUndefined(fieldConstraint) && fieldConstraint.allowsValidation();
    }

    /**
     * @returns {FieldConfiguration} Returns the FieldConfiguration for this field.
     */
    getConfiguration(): FieldConfiguration {
        this.checkFieldName();
        return this.validationRepository.findFieldConfigurationByName(this.fieldName);
    }

    /**
     * @returns {boolean} Returns true if this field is configured to be visible.
     */
    isFieldVisible(): boolean {
        var fieldConstraint: FieldConfiguration = this.getConfiguration();
        return _.isUndefined(fieldConstraint) || fieldConstraint.visible;
    }

    /**
     * @returns {boolean} Returns true if this field is configured to be enabled.
     */
    isFieldEnabled(): boolean {
        var fieldConstraint: FieldConfiguration = this.getConfiguration();
        return _.isUndefined(fieldConstraint) || fieldConstraint.enabled;
    }

    /**
     *
     * @returns {boolean} Returns true if error messages should be displayed.
     * Based on the dirty state of this model,
     * the dirty state of the parent controller and the submitted state of the form hierarchy.
     */
    public shouldShowMessages(): boolean {
        // convert to boolean ...
        return !!(
            // guard ... if there is no model nothing should happen
            this.ngModel
            // check if this field was edited or a parent was edited or any form is submitted
            && (this.isDirty() || this.isSubmitted())
        );
    }

    /**
     *
     * @returns {boolean} Returns true if this ng model is dirty or the parent controller is dirty.
     */
    public isDirty(): boolean {
        return !!(
            (this.ngModel && this.ngModel.$dirty)
            || (this.parent && this.parent.isDirty())
        );
    }

    /**
     *
     * @returns {string[]} Returns the user readable messages from this controller and its parents.
     */
    getMessages(): string[] {
        if (!this.shouldShowMessages()) {
            return [];
        }

        var errors: { [key: string]: boolean } = (this.ngModel && this.ngModel.$error) || {};
        var messages: string[] = _(errors)
            .chain()
            .map((key, value) => this.validationRepository.getValidationMessage('' + value))
            .filter((message) => !!message)
            .value();

        if (this.parent) {
            messages = messages.concat(this.parent.getMessages());
        }

        return messages;

    }

    /**
     *
     * @returns {Validator[]} This class returns a list of Validators that can be registered by a ngModel.
     */
    private getValidators(): Validator<boolean>[] {
        var configuration: FieldConfiguration = this.getConfiguration();
        if (!configuration || !configuration.allowsValidation()) {
            return [];
        }

        return configuration.constraints.filter((constraint) => {
            //ignore async constraints
            return !(constraint instanceof CallConstraint);
        }).map((constraint) => {
            var key = this.fieldName + '.' + constraint.key;
            return new Validator(key, (modelValue: any): boolean => {
                var successful = constraint.applies(modelValue);
                if (!successful) {
                    this.logger.debug('Validation failed: ' + key, {
                        constraint: constraint,
                        key: key,
                        modelValue: modelValue,
                        fieldConfiguration: configuration
                    });
                }
                return successful;
            });
        });
    }

    getAsyncValidators(): Validator<IPromise<AsyncValidationResult>>[] {
        var configuration: FieldConfiguration = this.getConfiguration();
        if (!configuration || !configuration.allowsValidation()) {
            return [];
        }

        return configuration.constraints.filter((constraint) => {
            //we only want to have asyncvalidators
            return constraint instanceof CallConstraint;
        }).map((constraint) => {
            var key = this.fieldName + '.' + constraint.key;
            return new Validator(key, (modelValue: any): IPromise<AsyncValidationResult> => {
                var handleCallPromise: IPromise<AsyncValidationResult> = this.callService.handleCall((<CallConstraint>constraint).call.withData(modelValue));
                return handleCallPromise.then((result: AsyncValidationResult) => {
                    if (result.validationStatus == ValidationStatus.SUCCESS) {
                        return handleCallPromise;
                    } else {
                        return this.$q.reject();
                    }
                });
                // return handleCallPromise;
            });
        });
    }

    /**
     * @returns {boolean} True if a form in the hierarchy is submitted.
     */
    public isSubmitted(): boolean {
        for (var ctrl = this.form; ctrl;) {
            if (ctrl.$submitted) {
                return true;
            }
            // yes this is a angular private field ...
            // if this is removed we must find another way to get the parent forms submitted state ...
            ctrl = ctrl['$$parentForm'];
        }
        return false;
    }

    public isRequired(): boolean {
        if (this.getConfiguration()) {
            return this.getConfiguration().isRequired();
        }
        return false;
    }
}

/**
 * Value class to hold a validator which is ready to use in an angular model
 */
export class Validator<T> {
    constructor(public key: string, public validationFunction: (modelValue: any) => T) {
    }
}
