import {Call} from './Call';
import {TrackingInfoValue} from './TrackingInfoValue';

/**
 * any object that provides
 * tracking infos can implement trackable
 * and is thus - indeed - Trackable.
 */
export interface Trackable {

    /**
     * marks an object as holder for tracking data.
     */
    trackingInfoCall: Call<TrackingInfoValue>;
}

export abstract class Trackable{}
