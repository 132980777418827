import {enumMetaInfo, valueMetaInfo} from './domain/MetaInfo';
import {classToFactory} from './global/coNg';
import {tracking} from './global/tracking';
import {mappingServiceProvider} from './service/MappingService';
import {createUtils, IUtilsService} from './service/Utils';
import {createLoggerService} from './service/LoggerService';
import {PostUrlService} from './service/PostURLService';
import {SnapshotService} from './service/SnapshotService';
import {FlowIdService} from './service/FlowIdService';
import {RenderModeService} from './service/RenderModeService';
import {CallService} from './service/CallService';
import {ContentService} from './service/ContentService';
import {FeedbackService} from './service/FeedbackService';
import {BusyService} from './service/BusyService';
import {DebugInfoService} from './service/DebugInfoService';
import {IDebugInfoService} from './service/IDebugInfoService';
import {AdaptiveService} from './service/AdaptiveService';
import {RelativeLocationService} from './service/RelativLocationService';
import {PageValueProvider} from './service/PageValueProvider';
import {IPageValueProvider} from './service/IPageValueProvider';
import {TrackingService} from './service/TrackingService';
import {IMappingServiceProvider} from './service/IMappingService';
import {IValidationRepositoryProvider} from './validation/IValidationRepositoryProvider';
import {coCentsFilter} from './filter/coCentsFilter';
import {coEuroFilter} from './filter/coEuroFilter';
import {coPriceFilter} from './filter/coPriceFilter';
import {coUnsafeHtmlFilter} from './filter/coUnsafeHtmlFilter';
import {coDraggable} from './directive/draggable/coDraggable';
import {coModalInfo} from './directive/modalInfo/coModalInfo';
import {coModalDialog} from './directive/modalDialog/coModalDialog';
import {coLoader} from './directive/loader/coLoader';
import {coTooltip} from './directive/tooltip/coTooltip';
import {coSeoHide} from './directive/seo/coSeoHide';
import {coSeoHref} from './directive/seo/coSeoHref';
import {coAffix} from './directive/affix/coAffix';
import {coIncludeHtml} from './directive/includeHtml/coIncludeHtml';
import {coFeedback} from './directive/feedback/feedbackPanel';
import {coScrollTo} from './directive/scrollTo/coScrollTo';
import {coScrollToTop} from './directive/scrollTo/coScrollToTop';
import {coCallAction} from './directive/callAction/coCallAction';
import {coResolveContent} from './directive/content/coResolveContent';
import {coAdaptiveIf} from './directive/adaptiveIf/coAdaptiveIf';
import {coRemoveLowerAttributeDirectives} from './directive/removeLowerAttributeDirectives/coRemoveLowerAttributeDirectives';
import {coUppercase} from './directive/uppercase/coUppercase';
import {coPostForm} from './directive/postForm/coPostForm';
import {coTrackingId} from './directive/tracking/coTrackingId';
import {floatLabel} from './directive/floatLabel/floatLabel';
import {TrackingController} from './directive/tracking/TrackingController';
import {
    AbstractCall,
    Call,
    ConstantCall,
    DeleteCall,
    FormSubmitEvent,
    GetCall,
    NavigationEvent,
    PostCall
} from './domain/Call';
import {GenericRequestError} from './domain/GenericRequestError';
import {Link} from './domain/Link';
import {LazyContent, ResolvedContent} from './domain/Content';
import {TrackingInfoValue} from './domain/TrackingInfoValue';
import {PiranhaLoginFormDataValue} from './domain/PiranhaLoginFormDataValue';
import {FieldConfiguration} from './validation/FieldConfiguration';
import {AcceptanceConstraint} from './validation/constraints/AcceptanceConstraint';
import {NumericConstraint} from './validation/constraints/NumericConstraint';
import {StringLengthConstraint} from './validation/constraints/StringLengthConstraint';
import {RequiredConstraint} from './validation/constraints/RequiredConstraint';
import {RegExpConstraint} from './validation/constraints/RegExpConstraint';
import {PreventSpecialCharConstraint} from './validation/constraints/PreventSpecialCharConstraint';
import {NumberRangeConstraint} from './validation/constraints/NumberRangeConstraint';
import {DateRangeConstraint} from './validation/constraints/DateRangeConstraint';
import {ValidDateConstraint} from './validation/constraints/ValidDateConstraint';
import {NameConstraint} from './validation/constraints/NameConstraint';
import {EmailConstraint} from './validation/constraints/EmailConstraint';
import {ConditionalConstraint} from './validation/constraints/ConditionalConstraint';
import {CallConstraint} from './validation/constraints/CallConstraint';
import {coField} from './validation/directive/coField/coField';
import {coFieldInput} from './validation/directive/coField/coFieldInput';
import {coFieldMessage} from './validation/directive/coField/coFieldMessage';
import {coFieldPopover} from './validation/directive/coField/coFieldPopover';
import {CoSlide} from './animations/coSlide';
import {snapshot} from './global/snapshot';
import {coClickPreventDefault} from './directive/clickPreventDefault/coClickPreventDefault';
import {EventService} from './service/EventService';
import {ExternalService} from './service/ExternalService';
import {Injectables} from './decorators/Injectables';
import {ValueMetaInfo} from './decorators/ValueMetaInfo';
import {ValueMetaProperty} from './decorators/ValueMetaProperty';
import {DebugToggle} from './decorators/DebugToggle';
import {IExternalService} from './service/IExternalService';
import {ICallService} from './service/ICallService';
import {IDebugUiToggleService} from './debugmodel/IDebugUiToggleService';
import {DebugUiToggleService} from './debugmodel/DebugUiToggleService';
import {CoDebugUi} from './debugmodel/debugUi/coDebugUi';
import {CoDebugUiElement} from './debugmodel/debugUiElement/coDebugUiElement';
import {DayOfWeekType} from "./domain/DayOfWeekType";
import {MockSettingsService} from "./service/MockSettingsService";
import {HeaderInterceptor} from "./interceptors/HeaderInterceptor";
import {IAngularStatic} from "angular";
import * as _ from "underscore";
import {coCsiApp} from "./directive/csiApp/coCsiApp";
import {coInitializeExternalContent} from "./directive/initializeExternalContent/coInitializeExternalContent";
import {coTrackingAttributes} from "./directive/trackingAttributes/coTrackingAttributes";
import {TrackingAttributesService} from "./service/TrackingAttributesService";
import {ITrackingAttributesService} from './service/ITrackingAttributesService';
import {coTrackingProductLines} from "./directive/trackingAttributes/coTrackingProductLines";
import {InvisibleFieldMappedConstraint} from './validation/constraints/InvisibleFieldMappedConstraint';
import {TrackingProductLine} from "./domain/TrackingProductLine";
import {coTrackingAttributesCsi} from "./directive/trackingAttributes/coTrackingAttributesCsi";


export {snapshot, tracking, classToFactory};
export {valueMetaInfo, enumMetaInfo};
export {
    CallService,
    ConstantCall,
    DeleteCall,
    GetCall,
    PostCall,
    NavigationEvent,
    FormSubmitEvent,
    Call,
    AbstractCall
}
export {Link}
export {
    AcceptanceConstraint,
    NumericConstraint,
    StringLengthConstraint,
    RequiredConstraint,
    RegExpConstraint,
    PreventSpecialCharConstraint,
    NumberRangeConstraint,
    DateRangeConstraint,
    ValidDateConstraint,
    NameConstraint,
    EmailConstraint,
    ConditionalConstraint,
    CallConstraint,
    InvisibleFieldMappedConstraint
}
export {
    coField,
    coFieldInput,
    coFieldMessage,
    coFieldPopover,
    coModalInfo,
    coTooltip,
    coScrollTo,
    coClickPreventDefault
}
export {GenericRequestError}
export {FieldConfiguration}
export {createLoggerService, createUtils, IUtilsService}
export {floatLabel}
export {PageValueProvider, IPageValueProvider}
export {coCallAction}
export {TrackingService, TrackingInfoValue}
export {TrackingAttributesService, ITrackingAttributesService}
export {ExternalService, IExternalService}
export {Injectables, ValueMetaInfo, ValueMetaProperty, DebugToggle}
export {IMappingServiceProvider}
export {ICallService}
export {IValidationRepositoryProvider}


export function loadModuleCommerceCommon(angular: IAngularStatic) {
    (<any>angular.module('commerce_common', ['ngAnimate'])


    // service provider
        .provider('mappingService', mappingServiceProvider)

        // service level dependencies
        .factory('utils', createUtils)
        .factory('loggerService', createLoggerService)
        .factory('postUrlService', classToFactory(PostUrlService))
        .factory('snapshotService', classToFactory(SnapshotService))
        .factory('flowIdService', classToFactory(FlowIdService))
        .factory('mockSettingsService', classToFactory(MockSettingsService))
        .factory('headerInterceptor', classToFactory(HeaderInterceptor))
        .factory('renderModeService', classToFactory(RenderModeService))
        .factory(ICallService.injectableName, classToFactory(CallService))
        .factory('contentService', classToFactory(ContentService))
        .factory('eventService', classToFactory(EventService))
        .factory('feedbackService', classToFactory(FeedbackService))
        .factory('busyService', classToFactory(BusyService))
        .factory('debugInfoService', classToFactory(DebugInfoService))
        .factory('adaptiveService', classToFactory(AdaptiveService))
        .factory(IExternalService.injectableName, classToFactory(ExternalService))
        .factory('relativeLocation', ['$location', function ($location: ng.ILocationService) {
            return new RelativeLocationService($location);
        }])
        .factory(IPageValueProvider.injectableName, classToFactory(PageValueProvider))
        .factory('trackingService', classToFactory(TrackingService))
        .factory('trackingAttributesService', classToFactory(TrackingAttributesService))
        .factory(IDebugUiToggleService.injectableName, classToFactory(DebugUiToggleService))
        .filter('coCents', coCentsFilter)
        .filter('coEuro', coEuroFilter)
        .filter('coPrice', coPriceFilter)
        .filter('coUnsafeHtml', coUnsafeHtmlFilter)

        .directive('coDraggable', coDraggable)
        .directive('coModalInfo', coModalInfo)
        .directive('coModalDialog', coModalDialog)
        .directive('coLoader', coLoader)
        .directive('coTooltip', coTooltip)
        .directive('coSeoHide', coSeoHide)
        .directive('coSeoHref', coSeoHref)
        .directive('coAffix', coAffix)
        .directive('coIncludeHtml', coIncludeHtml)
        .directive('coFeedback', coFeedback)
        .directive('coScrollTo', coScrollTo)
        .directive('coScrollToTop', coScrollToTop)
        .directive('coCallAction', coCallAction)
        .directive('coResolveContent', coResolveContent)
        .directive('coAdaptiveIf', coAdaptiveIf)
        .directive('coRemoveLowerAttributeDirectives', coRemoveLowerAttributeDirectives)
        .directive('coUppercase', coUppercase)
        .directive('coPostForm', coPostForm)
        .directive('coTrackingId', coTrackingId)
        // floatLabel directive should not have the "co" prefix, while used to redefine bootstrap data-float-label
        .directive('floatLabel', floatLabel)

        .directive('coInitializeExternalContent', coInitializeExternalContent)
        .directive('coTrackingAttributes', coTrackingAttributes)
        .directive('coTrackingProductLines', coTrackingProductLines)
        .directive("coTrackingAttributesCsi", coTrackingAttributesCsi)


        .directive('coClickPreventDefault', coClickPreventDefault)
        .directive("coCsiApp", coCsiApp)



        .controller('TrackingController', <any>TrackingController)
        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('headerInterceptor');
        }])

        .component('coDebugUi', new CoDebugUi())
        .component('coDebugUiElement', new CoDebugUiElement())

        .config([IMappingServiceProvider.injectableName, function (mappingServiceProvider: IMappingServiceProvider) {
            mappingServiceProvider.addAnnotatedType(ConstantCall);
            mappingServiceProvider.addAnnotatedType(DeleteCall);
            mappingServiceProvider.addAnnotatedType(GenericRequestError);
            mappingServiceProvider.addAnnotatedType(GetCall);
            mappingServiceProvider.addAnnotatedType(Link);
            mappingServiceProvider.addAnnotatedType(NavigationEvent);
            mappingServiceProvider.addAnnotatedType(FormSubmitEvent);
            mappingServiceProvider.addAnnotatedType(PostCall);
            mappingServiceProvider.addAnnotatedType(ResolvedContent);
            mappingServiceProvider.addAnnotatedType(LazyContent);
            mappingServiceProvider.addAnnotatedType(TrackingInfoValue);
            mappingServiceProvider.addAnnotatedType(TrackingProductLine);
            mappingServiceProvider.addAnnotatedType(PiranhaLoginFormDataValue);
            mappingServiceProvider.addCustomType(
                'infrastructure:LocalDate',
                (obj) => _.isDate(obj),
                (json) => {

                    let date = new Date(json.date);
                    // date['$$localDate'] = true;
                    return date
                },
                (date: Date) => {
                    // yes, we we need the outer curly braces to make sure this gets interpreted as an object
                    var year = date.getFullYear();
                    var month = date.getMonth() + 1;
                    var day = date.getDate();
                    return {date: year + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day}
                }
            );
            mappingServiceProvider.addCustomType(
                'infrastructure:DayOfWeek',
                (obj) => obj instanceof DayOfWeekType,
                (json) => DayOfWeekType.forName(json.name),
                (dayOfWeek: DayOfWeekType) => dayOfWeek.name
            )
        }])
        .run([IPageValueProvider.injectableName, function (pageValueSource: IPageValueProvider<{}>) {
            tracking.setupPageValueSource(() => pageValueSource.createPageValue());
        }])
        .run(['debugInfoService', function (debugInfoService: IDebugInfoService) {
            debugInfoService.logDebugInfo();
        }]))
        .animation('.co-slide', classToFactory(CoSlide))
    ;
}

