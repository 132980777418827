import {FieldController} from './FieldController';
import {IAttributes, IAugmentedJQuery, IDirective, IScope} from "angular";

/**
 * This directive is used to mark an element (typically an input) that should show a error messages popover.
 * This directive must be a child of a co-field directive.
 *
 */
export function coFieldMessageSemantic(): IDirective {
    return {
        require: ['^coField'],
        link: function (scope: IScope, element: IAugmentedJQuery, attr: IAttributes, controllers: any[]) {

            let fieldController: FieldController = controllers[0];

            // use this local variable to push messages into the help block via closure bindi..
            let helpBlockContent: string = '';

            // markup block for displaying validation messages, as required by the Compendium
            let helpBlockElement = $('<span class="help-block"></span>');
            // insert this block adjacent to the input element (also required by the Compendium)
            element.after(helpBlockElement);

            function updateBlock() {

                let fieldTouched = fieldController.ngModel.$touched,
                    formSubmitted = fieldController.form && fieldController.form.$submitted,
                    messages: string[] = fieldController.getMessages();

                helpBlockContent = messages.join('<br>');

                if (messages.length > 0 && (fieldTouched || formSubmitted)) {
                    // using ng-hide (or other CSS classes) to show and hide the block causes unwanted UI jumps
                    helpBlockElement.html(helpBlockContent);
                } else {
                    helpBlockElement.html('');
                }
            }

            // react on the field blur event
            element.on('blur', () => {
                // jQuery Blur doesn't trigger a digest cycle
                scope.$apply(updateBlock);
            });

            // watch for model changes on the field
            scope.$watch(() => {
                    return fieldController.ngModel.$touched;
                },
                updateBlock
            );

            // watch if the messages are changed or if the messages should be displayed or not ...
            scope.$watch(() => {
                    return fieldController.getMessages();
                },
                updateBlock,
                true
            );
        }

    };
}
