(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("angular"), require("reflect-metadata"), require("underscore"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define(["angular", "reflect-metadata", "underscore", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["common"] = factory(require("angular"), require("reflect-metadata"), require("underscore"), require("jquery"));
	else
		root["common"] = factory(root["angular"], root["Reflect"], root["_"], root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_angular__, __WEBPACK_EXTERNAL_MODULE_reflect_metadata__, __WEBPACK_EXTERNAL_MODULE_underscore__, __WEBPACK_EXTERNAL_MODULE_jquery__) {
return 