import {IDebugUiToggleService} from "../IDebugUiToggleService";
import {DebugUiToggleValue} from "../DebugUiToggleValue";
import {Injectables} from "../../decorators/Injectables";

@Injectables(IDebugUiToggleService)
export class DebugUiElementController {

    public toggle: DebugUiToggleValue;

    constructor(private debugUiToggleService: IDebugUiToggleService) {
    }

    set active(active: boolean) {
        this.debugUiToggleService.setActive(this.toggle, active);
    }

    get active() {
        return this.debugUiToggleService.isActive(this.toggle);
    }

    set value(value: any) {
        this.debugUiToggleService.setValue(this.toggle, value);
    }

    get value(): any {
        return this.debugUiToggleService.getValue(this.toggle);
    }

}
