export interface IFeedbackService {
    info(message: string): void;

    warn(message: string): void;

    error(message: string): void;

    isEmpty(): boolean;

    clear(): void;
}

export class IFeedbackService {
    static injectableName: string = 'feedbackService';
}
