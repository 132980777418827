import {IEventService} from './IEventService';
import * as _ from "underscore";


export class EventService implements IEventService {

    private subscribers: { [eventName: string]: Function[] } = {};

    public subscribe(eventName: string, callBack: Function) {
        if (!this.subscribers[eventName]) {
            this.subscribers[eventName] = [];
        }
        this.subscribers[eventName].push(callBack);
    }

    public emit(eventName: string, arg?: any) {
        if (this.subscribers[eventName]) {
            _.each(this.subscribers[eventName], (callBack: Function) => {
                try {
                    arg ? callBack(arg) : callBack();
                }
                catch (e) {
                    console.log('EventService error with eventName "' + eventName + '": ', e);
                    console.log('EventService error message: ', e.message);
                }
            });
        }
    }

}
