import {IValidationRepository} from './IValidationRepository';
import {Injectables} from '../decorators/Injectables';
import {ILogger, ILoggerService} from '../service/LoggerService';
import {IPageValueProvider} from '../service/IPageValueProvider';
import {FieldConfiguration} from './FieldConfiguration';
import * as _ from "underscore";


@Injectables(ILoggerService, IPageValueProvider)
export class ValidationRepository implements IValidationRepository {

    private logger: ILogger;
    private validationMessages: { [key: string]: string };

    constructor(loggerService: ILoggerService, private pageValueProvider: IPageValueProvider<any>, private pageValueNames: string[]) {
        this.logger = loggerService.create('commerce.common.ValidationRepository');
    }

    /**
     * just reads and maps field configurations from the page value.
     */
    public findAllFieldConfigurations(): FieldConfiguration[] {

        return this.pageValueNames.reduce((allFieldConfigurations, pageValueName) => {
            let pageValue = this.pageValueProvider.getPageValue(pageValueName);
            let fieldConfigurations = (pageValue && pageValue.fieldConfigurations) || [];

            return allFieldConfigurations.concat(fieldConfigurations);
        }, []);

    }

    /**
     * find a certain constraint by its name.
     */
    public findFieldConfigurationByName(name: string): FieldConfiguration {
        return _.chain(this.findAllFieldConfigurations()).filter((fieldConfiguration) => {
            return _.isEqual(fieldConfiguration.name, name);
        }).first().value();
    }

    public getValidationMessage(key: string): string {
        var validationMessages: { [key: string]: string } = this.getValidationMessages();

        if (!key || !validationMessages) {
            return undefined;
        }

        var msg = validationMessages[key];
        return msg;
    }

    public getValidationMessages(): { [key: string]: string } {

        if (!this.validationMessages) {
            this.validationMessages = this.pageValueNames.reduce((map: { [key: string]: string }, pageValueName: string) => {
                let pageValue = this.pageValueProvider.getPageValue(pageValueName);
                let validationMessage: { [key: string]: string } = pageValue.validationMessages;

                return _.extend({}, map, validationMessage);
            }, {});

        }
        return this.validationMessages;
    }

}
