import {FieldController} from './FieldController';
import {IAttributes, IAugmentedJQuery, IDirective, IScope} from "angular";

/**
 * This directive is used to mark an element (typically an input) that should show an error message.
 * This directive must be a child of a co-field directive.
 *
 */
export function coFieldMessage(): IDirective {
    return {
        require: ['^coField'],
        link: function (scope: IScope, element: IAugmentedJQuery, attr: IAttributes, controllers: any[]) {

            var fieldController: FieldController = controllers[0];

            // use this local variable to push messages into the popover via closure bindi..
            var messageContent: string = '';
            element.hide();

            function updateMessage() {

                var messages = fieldController.getMessages();
                messageContent = messages.join('<br><br>');
                element.text(messageContent);

                if (messages.length > 0) {
                    element.fadeIn("slow");
                } else {
                    element.hide();
                }
            }

            // watch if the messages are changed or if the messages should be displayed or not ...
            scope.$watch(() => {
                    return fieldController.getMessages();
                },
                updateMessage,
                true
            );
        }

    };
}
