import {IPostUrlService} from '../../service/PostURLService';
import {ILoggerService} from '../../service/LoggerService';
import {IDirective, IScope} from "angular";
import * as $ from "jquery";


coPostForm.$inject = ['postUrlService', 'loggerService'];

export function coPostForm(postUrlService: IPostUrlService, loggerService: ILoggerService): IDirective {

    var logger = loggerService.create('commerce.common.coPostForm');

    function link(scope: IScope, element: JQuery) {
        var form = $('<form class="hidden">');
        form.attr('method', 'post');
        form.attr('enctype', 'application/x-www-form-urlencoded');
        $(element).append(form);

        postUrlService.setFormSubmitCallback((data, url) => {
            buildFormAndPostToURL(data, url, form);
        });
        scope.$on('$destroy', () => postUrlService.resetCallback());

    }

    function buildFormAndPostToURL(data: { [key: string]: any }, targetURL: string, form: JQuery) {

        form.children().remove();
        form.attr('action', targetURL);

        for (var key in data) {
            if (data.hasOwnProperty(key)) {
                logger.info(key, data[key]);
                var field = $('<input>').attr('type', 'hidden').attr('name', key).val(data[key]);
                form.append(field);
            }
        }
        form.submit();
    }


    return {
        scope: {},
        link: link
    };
}
