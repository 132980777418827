import {CsiApp, CsiChannel, CsiMessage, CsiMessageListener} from "@shop/js-infrastructure-generic-csi";
import {IController} from "angular";

export class CsiAppController implements CsiChannel, IController {

    public csiApp: CsiApp;
    private name: string;
    private queuedMessages: CsiMessage[] = [];
    private messageListeners: { [key: string]: CsiMessageListener } = {};
    private listenerId = 0;
    private unsubscribeCallback: () => void;

    send(messsage: CsiMessage) {
        if (this.csiApp) {
            this.csiApp.channel.send(messsage);
        } else {
            this.queuedMessages.push(messsage);
        }
    }

    subscribe(fn: CsiMessageListener): () => void {
        // store the current id and increment ...
        const id = (this.listenerId ++ );
        this.messageListeners[id] = fn;
        return () => delete this.messageListeners[id];
    }

    initCsiApp(name: string, csiApp: CsiApp) {
        if (this.csiApp) {
            throw new Error(`csiApp '${this.name}' already initialised`);
        }
        this.csiApp = csiApp;

        // send the queued messages now that the csi is ready
        this.queuedMessages.forEach(message => this.csiApp.channel.send(message));
        // remove the message queue ... we directly send the messages to the csi ...
        this.queuedMessages = null;

        // now subscribe to the csi and forward the messages to our subscribers...
        this.unsubscribeCallback = this.csiApp.channel.subscribe((message: CsiMessage) => {
            Object.keys(this.messageListeners).forEach(key => {
                this.messageListeners[key].call(null, message)
            });
        });
    }

    $onDestroy(): void {
        if(this.unsubscribeCallback){
            this.unsubscribeCallback();
        }
    }

}