import {IDirective} from "angular";

export function coAffix(): IDirective {
    return {
        scope: {},
        link: (scope: any, element: any): void => {
            var markerDiv: JQuery = $('<div class="markerAffix" style="height: 0;"></div>');

            element.before(markerDiv);

            /* affix the navbar after scroll below header */
            element
                .affix({
                    offset: {
                        top: (): number => {
                            return markerDiv.offset().top;
                        }
                    }
                })
                .on('affixed.bs.affix', () => {
                    markerDiv.height(element.height());
                })
                .on('affixed-top.bs.affix', () => {
                    markerDiv.height(0);
                });

            scope.$on('$destroy', () => {
                markerDiv.remove();
            });
        }
    };
}
