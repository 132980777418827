import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import * as _ from "underscore";
import {IAugmentedJQuery} from "angular";


export class DateExcludedDatesConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:DateExcludedDatesConstraint', {
        key: 'string',
        excludedDates: 'Date[]'
    });

    public constructor(public key: string, private excludedDates: Date[]) {
    }

    public applies(value: any): boolean {
        if (!_.isDate(value)) {
            //required validation is handled elsewhere...
            return true;
        }

        return !this.excludedDates.some(excluded => DateExcludedDatesConstraint.isEqualDay(excluded, value));
    }

    public static isEqualDay(date1: Date, date2: Date): boolean {
        // avoid the entire time comparison magic and simply use the date string
        return date1.toDateString() === date2.toDateString();
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
