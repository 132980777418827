import {IBusyService} from '../../service/IBusyService';
import * as _ from "underscore";
import {IDirective} from "angular";


/**
 * Directive to bind a loader to an element.
 *
 * The loader overlay is going to be bound to the parent element and depends on the Call Service to get triggered.
 * A boolean value available at the Call Service (isCalling) is being watched.
 *
 * If the call:
 * - gets a quick response - the overlay won't be shown
 * - gets a response that isn't quick but also isn't long - the animation will be displayed for a set amount of time
 * - gets a response that takes a long time - the overlay will be removed once the call gets a response
 * - gets a response that takes very long time - the overlay will show after a couple
 *
 * This is done so that users don't get flickers because most of the calls are very quick.
 *
 * @param callService Call Service to watch for isCalling
 * @returns ng.IDirective Angular Directive
 */
export function coLoader(busyService: IBusyService): IDirective {
    const waitingTimeLimit = 600; // How long to wait before adding overlay
    const waitingTimeDelay = 600; // How long to display the loader

    return {
        link: (scope, element: any) => {
            // Check if the element is outside of body
            let parent = element.parent().is('html') ? element : element.parent();
            parent.attr('waiting-time', 'none');
            let position = parent.css('position');

            let removeLoader = () => {
                parent.attr('waiting-time', 'none');
                parent.removeClass('has-loading-overlay loading-overlay-position');
                parent.children('.loading-overlay').remove();
            };

            let createOverlayMarkup = (title: string, subtitle: string, isSlowOverlay: boolean) => {
                let loaderMarkup = '';
                if (isSlowOverlay) {
                    // TODO: OSD-2254 Here we should add animation for slow transition when it is available in Compendium
                    loaderMarkup = '<div class="loading-spinner-2"><div class="bounce1"></div>' +
                        '<div class="bounce2"></div><div class="bounce3"></div></div>';
                } else {
                    loaderMarkup = '<div class="loading-spinner-2"><div class="bounce1"></div>' +
                        '<div class="bounce2"></div><div class="bounce3"></div></div>';
                }

                if (title) {
                    loaderMarkup = `<h2>${title}</h2> ${loaderMarkup}`;
                }

                if (subtitle) {
                    loaderMarkup = `${loaderMarkup} <p><strong>${subtitle}</strong></p>`;
                }

                let overlayMarkup =
                    `<div class="loading-overlay">
                        <div class="loading-overlay-spinner" style="height: auto; margin: 0; transform: translateY(-50%);">
                            ${loaderMarkup}
                        </div>
                    </div>`;
                return overlayMarkup;
            };

            scope.$watch(() => {
                return busyService.isBusy();
            }, () => {
                // The Call Service started a call
                if (busyService.isBusy()) {
                    let overlayMarkup = createOverlayMarkup(busyService.title, busyService.subtitle, false);

                    parent.attr('waiting-time', 'short');
                    if (!position || position === '' || position === 'static') {
                        parent.addClass('loading-overlay-position');
                    }
                    parent.addClass('has-loading-overlay');

                    setTimeout(() => {
                        let waitingTime = parent.attr('waiting-time');
                        // If the call lasted a longer time, wait a while and then reset it
                        if (waitingTime === 'short') {
                            parent.attr('waiting-time', 'long');
                            parent.append(overlayMarkup);

                            // Wait for a short amount of time to display overlay
                            setTimeout(() => {
                                let waitingTime = parent.attr('waiting-time');
                                // The call is done while the animation was playing
                                if (waitingTime === 'done') {
                                    removeLoader();
                                }
                                // The call still isn't done - let the animation break when the call is done
                                if (waitingTime === 'long') {
                                    parent.attr('waiting-time', 'longer');
                                }
                            }, waitingTimeDelay);
                        }
                    }, waitingTimeLimit);

                    // If slowTimeout is set means that we should show another text after 'slowTimeout' milliseconds
                    let slowTimeout: number = busyService.slowTimeout;
                    if (!_.isNull(slowTimeout)) {
                        setTimeout(() => {
                            removeLoader();
                            // Before we add the overlay we ask the busy service again if it is still busy
                            // in order to prevent a never ending waiting animation loop
                            if (busyService.isBusy()) {
                                parent.attr('waiting-time', 'long');
                                let overlayMarkup = createOverlayMarkup(
                                    busyService.titleSlow,
                                    busyService.subtitleSlow,
                                    true);

                                parent.attr('waiting-time', 'short');
                                if (!position || position === '' || position === 'static') {
                                    parent.addClass('loading-overlay-position');
                                }
                                parent.addClass('has-loading-overlay');
                                parent.append(overlayMarkup);
                            }
                        }, slowTimeout);
                    }
                }
                // The Call Service ended a call
                else {
                    let waitingTime = parent.attr('waiting-time');
                    // If the call lasted a short time or longer than the minimum animation
                    // time, reset the loader and remove it
                    if (waitingTime === 'short' || waitingTime === 'longer') {
                        removeLoader();
                    }
                    // If the call ended at a point in time when the animation was playing
                    else if (waitingTime === 'long') {
                        parent.attr('waiting-time', 'done');
                    }
                }
            });
        }
    };
}

coLoader.$inject = <any>['busyService'];
