import {DisplayMoney} from './DisplayMoney';

export function coPriceFilter() {
    return function (input: number, displayZeroAs: string): string {
        var money = new DisplayMoney(input);
        if (!money.euros || !money.cents) {
            return '';
        }
        if (displayZeroAs != undefined && money.euros == "0" && money.cents == "00") {
            return displayZeroAs;
        }
        else {
            return money.euros + ',' + money.cents + ' ' + money.currency;
        }
    }
}
