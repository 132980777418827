import * as jQuery from "jquery";

export class CoSlide {

    enter(element, doneFn) {
        element.finish();
        // initial "instant" slideUp needed since the element is inserted via ng-if
        element.slideUp(0, () => {
            element.slideDown(350, doneFn);
        });
    }

    leave(element, doneFn) {
        element.finish();
        jQuery(element).slideUp(350, doneFn);
    }
}
