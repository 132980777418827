import {IDirective} from "angular";

export function coUppercase(): IDirective {
    return {
        require: 'ngModel',

        link: (scope, element, attrs, modelCtrl: any) => {
            modelCtrl.$parsers.push(function (inputValue) {
                if (inputValue !== undefined) {
                    var updatedInputValue = inputValue.toUpperCase();
                    modelCtrl.$setViewValue(updatedInputValue);
                    modelCtrl.$render();

                    return updatedInputValue;
                } else {
                    return inputValue;
                }
            });
        }

    }
}
