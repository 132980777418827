import {IUtilsService} from './Utils';
import * as _ from "underscore";


'use strict';

export interface ILogger {
    name: string

    error(subject: string, context?: {});

    warn(subject: string, context?: {});

    info(subject: string, context?: {});

    debug(subject: string, context?: {});

    sub(name: string): ILogger
}

export interface ILoggerService {
    DEBUG: number;
    INFO: number;
    WARN: number;
    ERROR: number;

    create(name: string): ILogger

    config: ILoggerConfigService
}

export class ILoggerService {
    static injectableName: string = 'loggerService';
}

export interface ILoggerConfigService {
    set(config: {}): void

    get(): {}

    add(name: string, level: number): void

    reset(): void

    fromUrl(url: string): void
}

createLoggerService.$inject = ['utils'];

export function createLoggerService(utils: IUtilsService) {
    'use strict';
    var LEVELS = {
        OFF: 40,
        ERROR: 30,
        WARN: 20,
        INFO: 10,
        DEBUG: 0
    };

    var DEFAULT_CONFIG = {
        '*': LEVELS.ERROR,
        'commerce.log.*': LEVELS.INFO
    };

    /**
     * The Logger used to log system events in the log package,
     * it is initialised as noop object and will will be replaced
     * after the base log infrastructure is initialized.
     *
     * @type {{error: Function, warn: Function, info: Function, debug: Function}} a logger instance.
     */
    var logLogger: ILogger = {
        name: 'logLogger',
        error: function (subject: string, context?: {}) {
        },
        warn: function (subject: string, context?: {}) {
        },
        info: function (subject: string, context?: {}) {
        },
        debug: function (subject: string, context?: {}) {
        },
        sub: function (name: string) {
            return null;
        }
    };

    var logFunctionFactory = function (levelName) {
        if (typeof(window['console']) === 'undefined') {
            return function () {
            };
        }
        var consoleFunction = window['console'][levelName.toLowerCase()];

        if (!_.isFunction(consoleFunction)) {
            return function () {
                consoleFunction = window['console']['log'];
            };
        }
        return function () {
            consoleFunction.apply(window['console'], arguments);
        };
    };

    /**
     * currently set config
     * The key should be a pattern string with '*' as wildcard
     * The value is a number or string of the required level.
     */
    var currentConfig = {};

    /**
     * Compiled config rules
     * @type {Array<CompiledRule>}
     *
     */
    var currentCompiledConfig = [];

    function CompiledRule(regexp, level, pattern) {
        this.regexp = regexp;
        this.level = level;
        this.pattern = pattern;
    }

    /**
     * This function tries to create an error level from a number or a string.
     * @param {string|number} level A errror level representated as a number or as a error level name,
     * the case does not matter.
     * @return {number}
     */
    function interpreteLevel(level) {
        if (typeof level === 'string') {
            return LEVELS[level.toUpperCase().trim()] || LEVELS.DEBUG;
        }
        if (typeof level === 'number') {
            return level;
        }
        logLogger.warn('Failed to interprete level!', {level: level});
        return LEVELS.DEBUG;
    }

    /**
     * This function compiles a config object by creating RegExp objects from the string keys
     * and tries to interprete the value as an error level.
     *
     * @param {{}} config The key must be a string, the value could be a string or a number.
     */
    function compileConfig(config) {
        var pattern;

        var compiledConfig = [];
        for (pattern in config) {
            if (config.hasOwnProperty(pattern)) {
                // escape '.' in pattern and replace stars with '.*' for regexp processing
                var regexp = utils.regExpFromSimplePattern(pattern);
                compiledConfig.push(new CompiledRule(regexp, interpreteLevel(config[pattern]), pattern));
            }
        }

        // sort rules so that the longest rules are applied first
        return compiledConfig.sort(function (obj1, obj2) {
            return obj2.pattern.length - obj1.pattern.length;
        });
    }

    /**
     * This function compiles and loads an config object.
     * @param {{}} config
     */
    function loadLogConfig(config) {
        try {
            currentCompiledConfig = compileConfig(config);
            currentConfig = config;
        } catch (e) {
            logLogger.error('Failed compiling config!', {config: config, exception: e});
        }
    }

    /**
     * This function stores the config in local storage and set it as current config.
     * @param {{}} config
     */
    function setLogConfig(config) {
        var localStorage = utils.getLocalStorage();
        if (localStorage !== null) {
            logLogger.info('writing config to local storage', {config: config});
            localStorage['logConfig'] = JSON.stringify(config);
        } else {
            logLogger.warn('Can not set log config, no local storage supported!', undefined);
        }
        loadLogConfig(config);
    }

    /**
     * This function retrieve the current config from local storage.
     */
    function getLogConfig() {
        var currentLogSettings = DEFAULT_CONFIG;
        var localStorage = utils.getLocalStorage();
        if (localStorage) {
            var configString = localStorage['logConfig'];
            if (configString) {
                try {
                    currentLogSettings = JSON.parse(configString);
                } catch (e) {
                    logLogger.error(
                        'Failed reading config from local storage!',
                        {string: configString, exception: e}
                    );
                }
            }
        }
        return currentLogSettings;
    }

    /**
     * This function adds a new rule to the current log config.
     * @param {string} loggerNamePart A pattern to filter the logger name, with '*' as wildcard character.
     * @param {string|number} level The log level that should be applied to the matched logger,
     * can be the log level name or the log level number.
     */
    function addLogConfig(loggerNamePart, level) {
        var config = getLogConfig();
        config[loggerNamePart] = level;
        setLogConfig(config);
    }

    /**
     * This function reset the log config to the default value.
     */
    function resetLogConfig() {
        setLogConfig(DEFAULT_CONFIG);
        logLogger.info('config reset', undefined);
    }

    /**
     * This function extracts the value of the jsLogConfig parameter from an url string.
     * @param {string} url that should be parsed.
     * @return {string} the value of the url parameter.
     */
    function getLogConfigStringFromUrl(url) {
        var regexp = new RegExp('[?&]jsLogConfig=([\'"a-zA-Z0-9._:,*]+)');
        var match = regexp.exec(url);
        return (match && match[1]) || '';
    }

    /**
     * This function parses an string to an object which keys and values are strings.
     * Each entry is separated by an ',' and key and value is separated by ':'
     *
     * @param {string} configString a string in the format "key:value,key1:value2"
     * that should be parsed to an object.
     *
     * @return {{}}
     */
    function parseConfigString(configString) {
        var rules = configString.split(',');
        var config = {};
        var i;
        for (i = 0; i < rules.length; i += 1) {
            var rule = rules[i];
            var tokens = rule.split(':');
            if (tokens.length === 2) {
                config[tokens[0]] = tokens[1];
            }
        }
        return config;
    }

    /**
     * Reads the current url of the browser and searches for the query parameter
     * 'jsLogConfig' and constructs a log config from its value.
     * This config is stored in the local storage to survives a page reload.
     */
    function setupLogConfigFromUrl() {
        var url = window.location.href;

        // find the url parameter value in a basic way without help of a lib
        var configString = getLogConfigStringFromUrl(url);
        if (configString.length !== 0) {
            if (configString === 'reset') {
                resetLogConfig();
            } else {
                var config = parseConfigString(configString);
                logLogger.info('found config in page url', {config: config});
                setLogConfig(config);
            }
        } else {
            loadLogConfig(getLogConfig());
        }
    }

    /**
     * simple utilitiy function to create a readable string representation of a integer log level.
     * @param {number} level the log level.
     * @return {string} the string representation.
     */
    function levelToString(level) {
        var levelName;

        for (levelName in LEVELS) {
            if (LEVELS.hasOwnProperty(levelName) && level === LEVELS[levelName]) {
                return levelName;
            }
        }
        return 'UNKNOWN';
    }

    /**
     * This function determines if a message should be suppressed according to the log config.
     *
     * @param {string} loggerName of the message.
     * @param {number} level the log level of the message.
     * @return {boolean} if true the message should not be processed.
     */
    function isMessageSuppressed(loggerName, level) {
        var i,
            configRule;

        for (i = 0; i < currentCompiledConfig.length; i += 1) {
            configRule = currentCompiledConfig[i];
            if (configRule.regexp.exec(loggerName)) {
                return configRule.level > level;
            }
        }
        return false;
    }


    /**
     * This function implements the actual logging to the Console object.
     *
     * @param {string} loggerName the logical name of a logger, this should correspond to the namespace
     *                    of the object that logs the message.
     * @param {number} level the log level of the message.
     * values that should be logged with the message.
     * @return {function(*)} returns a functions that supports variable arguments that are all logged.
     */
    function createLogFunction(loggerName, level) {

        var levelName = levelToString(level);
        var evt = '[';
        evt += levelName;
        evt += '][';
        evt += loggerName;
        evt += ']';


        return function () {
            if (isMessageSuppressed(loggerName, level)) {
                return;
            }
            var array = [];
            array.push(evt);
            for (var i = 0; i < arguments.length; i += 1) {
                var arg = arguments[i];
                array.push(arg);
            }
            logFunctionFactory(levelName).apply(null, array);
        };
    }

    /**
     * Function that creates a new logger object for the supplied loggerName.
     * a object that contains a function for each log level.
     */
    function createLogObject(loggerName): ILogger {
        var levelName,
            logger = {};

        for (levelName in LEVELS) {
            if (LEVELS.hasOwnProperty(levelName) && levelName !== 'OFF') {
                logger[levelName.toLowerCase()] = createLogFunction(loggerName, LEVELS[levelName]);
            }
        }
        logger['name'] = loggerName;
        logger['sub'] = function (subName) {
            return createLogObject(loggerName + "/" + subName);
        };
        return <ILogger>logger;
    }

    function setupLogFunctionFactory(f) {
        if (typeof(f) !== 'function') {
            throw new TypeError('LogFunctionFactory must be a Function');
        }
        logFunctionFactory = f;
    }

    // create a logger instance for the usage in the log package
    logLogger = createLogObject('commerce.common.log');

    setupLogConfigFromUrl();


    // publish api
    var logService: ILoggerService = {
        create: createLogObject,
        DEBUG: LEVELS.DEBUG,
        INFO: LEVELS.INFO,
        WARN: LEVELS.WARN,
        ERROR: LEVELS.ERROR,
        config: {
            'set': setLogConfig,
            'get': getLogConfig,
            'add': addLogConfig,
            'reset': resetLogConfig,
            'fromUrl': setupLogConfigFromUrl
        }
    };
    return logService;
}
