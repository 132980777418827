import {IDirective} from "angular";

export function coTooltip(): IDirective {
    return {
        scope: {
            placement: '@coTooltipPlacement',
            container: '@coTooltipContainer',
            title: '@coTooltipTitle',
        },
        link: (scope: ng.IScope, element: any) => {
            scope.$watchGroup(['placement', 'container', 'title'], function () {
                var options = {
                    placement: scope['placement'] || 'top',
                    container: scope['container'] || false,
                    title: scope['title']
                };

                element.tooltip(options);
            });
        }
    }
}
