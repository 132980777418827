import {Call} from './Call';
import {valueMetaInfo, MetaInfo} from './MetaInfo';



/**
 * This class is used to abstract a content that is either already resolved or can be lazily loaded.
 * Should be handled by {@link IContentService} to execute resolve the content.
 */
export class Content {

    static fromObj(obj): Content {
        if (obj.contentType === 'RESOLVED') {
            return Content.resolvedContent(obj.content);
        }
        else {
            return Content.lazyContent(obj.callForContent);
        }
    }

    static resolvedContent(content: string): ResolvedContent {
        return new ResolvedContent(content);
    }

    static lazyContent(callForContent: Call<Content>): LazyContent {
        return new LazyContent(callForContent);
    }

    constructor() {
    }

}

export class ResolvedContent extends Content {

    static META_INFO: () => MetaInfo = () => valueMetaInfo('infrastructure:ResolvedContent', {
        content: 'string'
    });

    constructor(public content: string) {
        super();
        argsContract(arguments, 'string');
    }

}

export class LazyContent extends Content {

    static META_INFO: () => MetaInfo = () => valueMetaInfo('infrastructure:LazyContent', {
        callForContent: 'Call'
    });

    constructor(public callForContent: Call<string>) {
        super();
        argsContract(arguments, 'GetCall');
    }

}
