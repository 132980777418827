import {ValidationStatus} from './ValidationStatus';
import {MetaInfo, valueMetaInfo} from '../domain/MetaInfo';

/**
 * Validation Result of an async call (Remote Validation)
 */
export class AsyncValidationResult {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:AsyncValidationResult', {
        validationStatus: 'ValidationStatus',
    });

    constructor(public validationStatus: ValidationStatus) {
    }
}
