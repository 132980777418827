import {FieldController} from './FieldController';
import {IAttributes, IDirective, IScope} from "angular";

/**
 * This directive is used to mark an element (typically an input) that should show a error messages popover.
 * This directive must be a child of a co-field directive.
 *
 */
export function coFieldPopover(): IDirective {
    return {
        require: ['^coField'],
        link: function (scope: IScope, element: any, attr: IAttributes, controllers: any[]) {

            var fieldController: FieldController = controllers[0];

            // use this local variable to push messages into the popover via closure bindi..
            var popoverContent: string = '';

            function updatePopover() {

                var messages = fieldController.getMessages();
                popoverContent = messages.join('<br><br>');

                // use a class to simulate a focus ...
                // focus is untestable, in the case you can focus the element in the test,
                // a gui interaction can break it...
                if (messages.length > 0 && (element.is(':focus') || element.hasClass('focused')) && (element.hasClass('ng-touched'))) {
                    element.popover('show');
                } else {
                    element.popover('hide');
                }
            }

            element.popover({
                trigger: 'manual',
                placement: 'bottom',
                html: true,
                content: () => popoverContent
            });

            element.on('focus', function () {
                element.popover('show');
            });

            element.on('blur', function () {
                element.popover('hide');
            });

            // watch if the messages are changed or if the messages should be displayed or not ...
            scope.$watch(() => {
                    return fieldController.getMessages();
                },
                updatePopover,
                true
            );
        }

    };
}
