import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {DayOfWeekType} from "../../domain/DayOfWeekType";
import * as _ from "underscore";
import {IAugmentedJQuery} from "angular";


export class DateDaysOfWeekConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:DateDaysOfWeekConstraint', {
        key: 'string',
        excludedDaysOfWeek: 'DayOfWeekType[]'
    });

    public constructor(public key: string, private excludedDaysOfWeek: DayOfWeekType[]) {
    }

    public applies(value: any): boolean {
        if (!_.isDate(value)) {
            //required validation is handled elsewhere...
            return true;
        }

        return !this.excludedDaysOfWeek.some(excludedDayOfWeek => DateDaysOfWeekConstraint.isExcludedDayOfWeek(excludedDayOfWeek, value));
    }

    public static isExcludedDayOfWeek(excludedDayOfWeek: DayOfWeekType, date: Date): boolean {
        return excludedDayOfWeek.index === date.getDay();
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
