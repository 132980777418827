import {IBusyService} from './IBusyService';


export class BusyService implements IBusyService {

    private busyProcesses: number = 0;

    private _title: string = null;
    private _subtitle: string = null;
    private _titleSlow: string = null;
    private _subtitleSlow: string = null;
    private _slowTimeout: number = null;

    constructor() {
    }

    isBusy(): boolean {
        return this.busyProcesses !== 0;
    }

    busy<T>(promise: ng.IPromise<T>, loaderConfig?: {
        title: string,
        subtitle: string,
        titleSlow: string,
        subtitleSlow: string,
        slowTimeout: number
    }): ng.IPromise<T> {
        this.busyProcesses++;
        this.setConfig(loaderConfig);
        return promise.then((t: T) => {
            this.decrementBusyProcesses();
            return t;
        }, (t: T) => {
            this.decrementBusyProcesses();
            return t;
        });
    }

    busyWithPreservedPromise<T>(promise: ng.IPromise<T>, loaderConfig?: {
        title: string,
        subtitle: string,
        titleSlow: string,
        subtitleSlow: string,
        slowTimeout: number
    }): ng.IPromise<T> {
        this.busyProcesses++;
        this.setConfig(loaderConfig)
        return promise.finally(() => this.decrementBusyProcesses());
    }

    private setConfig(loaderConfig?: { title: string; subtitle: string; titleSlow: string; subtitleSlow: string; slowTimeout: number }) {
        if (loaderConfig) {
            this._title = loaderConfig.title;
            this._subtitle = loaderConfig.subtitle;
            this._titleSlow = loaderConfig.titleSlow;
            this._subtitleSlow = loaderConfig.subtitleSlow;
            this._slowTimeout = loaderConfig.slowTimeout;
        }
    }

    private decrementBusyProcesses(): void {
        this.busyProcesses--;
        if (this.busyProcesses == 0) {
            this._title = null;
            this._subtitle = null;
        }
    }

    public get title(): string {
        return this._title;
    }

    public get subtitle(): string {
        return this._subtitle;
    }

    public get titleSlow(): string {
        return this._titleSlow;
    }

    public get subtitleSlow(): string {
        return this._subtitleSlow;
    }

    get slowTimeout(): number {
        return this._slowTimeout;
    }
}
