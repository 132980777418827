// import {enumMetaInfo} from "./MetaInfo";

import {enumMetaInfo, MetaInfo} from "./MetaInfo";

export type TrackingProductLineName = 'SIM_ONLY' | 'HARDWARE_ONLY' | 'BUNDLE' | 'DSL' | 'GADGETS';

export class TrackingProductLine {

    public static readonly META_INFO:MetaInfo = enumMetaInfo("infrastructure:TrackingProductLine");

    private static readonly VALUES: { [key: string]: TrackingProductLine } = {};

    public static readonly SIM_ONLY = new TrackingProductLine('SIM_ONLY', 'simOnly');
    public static readonly HARDWARE_ONLY = new TrackingProductLine('HARDWARE_ONLY', 'hardwareOnly');
    public static readonly BUNDLE = new TrackingProductLine('BUNDLE', 'bundle');
    public static readonly DSL = new TrackingProductLine('DSL', 'dsl');
    public static readonly GADGETS = new TrackingProductLine('GADGETS', 'gadgets');

    public static forName(name: string): TrackingProductLine{
        return TrackingProductLine.VALUES[name] || null;
    }

    private constructor(public readonly name: TrackingProductLineName, public readonly trackingValue: string) {
        TrackingProductLine.VALUES[name] = this;
    }


    /**
     * This method is for safe compare of enum instances.
     * Angular may create copies from enums during scope checks ... so we use a compare of a value attribute instead of
     * the identity.

     * @param {TrackingProductLine} other
     * @returns {boolean} true if it is the same enum instance.
     */
    public is(other: TrackingProductLine): boolean{
        if(!other){
            return false;
        }
        return this.name === other.name;
    }
}

