import * as $ from "jquery";
import coModalInfoTemplate from "./coModalInfoTemplate.html";

coModalInfo.$inject = ['$compile', "$timeout"];

export function coModalInfo($compile: ng.ICompileService, $timeout: ng.ITimeoutService): ng.IDirective {
    return {
        scope: false,
        link: function (scope, element, attrs) {
            // create a new scope, since we can't rely on an isolate scope here
            let newScope = scope.$new(true, scope);

            let modal = <ng.IAugmentedJQuery & { modal: any }>$compile(coModalInfoTemplate)(newScope, function (clonedElement, scope) {
                clonedElement.insertAfter(element);
            });

            const $BODY = $(document.body);
            modal.on('hide.bs.modal', () => {
                // Very stupid to do - this resembles the callback on backdrop() in Bootstrap's modal.js:
                $BODY.removeClass('modal-open');
                modal.css({
                    paddingLeft: '',
                    paddingRight: ''
                });
                $BODY.css('padding-right', '');
                modal.trigger('hidden.bs.modal');
            });

            scope.$on('$destroy', function () {
                newScope.$destroy();
                modal.remove();
            });

            element.on('click', ((e: JQuery.Event) => {

                e.stopPropagation();

                // wrap this in a timeout block ...
                // it seems that something on outside the click sometimes starts a digest cycle ...
                $timeout(() => {
                    // the model content is either loaded externally via the provided URL or passed directly
                    newScope.$apply(() => {
                        let url = attrs['coModalInfoUrl'];
                        let modalContent = attrs['coModalInfoContent'];
                        let titleContent = attrs['coModalInfoTitle'];

                        if (url) {
                            $.get(url, null, (result) => {
                                newScope.$apply(() => {
                                    newScope['body'] = result;
                                })
                            });
                        }
                        if (modalContent) {
                            newScope['body'] = modalContent;
                        }

                        if (titleContent) {
                            newScope['title'] = titleContent;
                        } else {
                            newScope['title'] = null;
                        }

                        modal.modal('show');
                        e.preventDefault();
                    });
                }, 0);
            }))
        }
    };
}
