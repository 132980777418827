import {IValidationRepositoryProvider} from './IValidationRepositoryProvider';
import {ILoggerService} from '../service/LoggerService';
import {IPageValueProvider} from '../service/IPageValueProvider';
import {ValidationRepository} from './ValidationRepository';

export function validationRepositoryProvider(): IValidationRepositoryProvider {

    let pageValueNames: string[] = [];

    // exposed here to circumvent 'this' reference...
    function create(loggerService, pageValueProvider) {
        return new ValidationRepository(loggerService, pageValueProvider, pageValueNames);
    }

    return {

        create: create,

        $get: ['loggerService', 'pageValueProvider', (loggerService: ILoggerService, pageValueProvider: IPageValueProvider<any>) => {
            return create(loggerService, pageValueProvider);
        }],

        addPageValueName: function (pageValueName: string): void {
            pageValueNames.push(pageValueName);
        },

        addDefaultPageValueName(): void {
            this.addPageValueName(IPageValueProvider.defaultPageValueName);
        }
    };

}
