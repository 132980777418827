import {IMappingService} from './IMappingService';
import {ILogger, ILoggerService} from './LoggerService';
import {IBusyService} from './IBusyService';
import {ICallService} from './ICallService';
import {IPostUrlService} from './PostURLService';
import {
    AbstractCall,
    Call,
    ConstantCall,
    DeleteCall,
    FormSubmitEvent,
    GetCall,
    NavigationEvent,
    PostCall
} from '../domain/Call';
import {Link} from '../domain/Link';

import * as _ from "underscore";
import {IDocumentService, IHttpPromise, IHttpService, IPromise, IQService, IWindowService} from "angular";


export class CallService implements ICallService {

    static $inject = ['$document', '$http', '$window', 'mappingService', '$q', 'busyService', 'postUrlService', 'loggerService'];

    static RECURSION_LIMIT: number = 10;

    private logger: ILogger;

    constructor(private $document: IDocumentService,
                private $http: IHttpService,
                private $window: IWindowService,
                private mappingService: IMappingService,
                private $q: IQService,
                private busyService: IBusyService,
                private postUrlService: IPostUrlService,
                private loggerService: ILoggerService) {
        this.logger = loggerService.create('commerce.common.MappingService');
    }

    handleCall<T>(call: Call<T>, recursionCounter: number = 0): ng.IPromise<T> {
        argsContract(arguments, 'Call, number?');
        this.logger.debug('CallService.handleCall: ' + JSON.stringify(call));

        if (recursionCounter >= CallService.RECURSION_LIMIT) {
            throw new Error('Trying to have more than 10 recursive Calls, this looks like an infinite loop... ');
        }

        if (call instanceof ConstantCall) {
            return this.handleConstantCall(<ConstantCall<T>>call);
        } else {
            // otherwise we do a ajax call
            var restCall: AbstractCall<T> = <AbstractCall<T>>call;
            var resultPromise: ng.IPromise<T> = this.doExec(restCall);

            return resultPromise.then((p: any): ng.IPromise<T> => {
                // check if the result is a constant call (e.g. navigationEvent)...
                if (p instanceof ConstantCall) {
                    var constantCall: ConstantCall<T> = <ConstantCall<T>>p;
                    return this.handleCall(constantCall, recursionCounter + 1);
                }
                // or a AbstractCall
                if (p instanceof AbstractCall) {
                    var abstractCall: AbstractCall<T> = <AbstractCall<T>>(<any>p);
                    return this.handleCall(abstractCall, recursionCounter + 1);
                }
                return p;
            }).catch((requestError: any): IPromise<T> => {
                var deferred = this.$q.defer<T>();
                var parsedRequestError = this.mappingService.fromJson(requestError.data);
                deferred.reject(parsedRequestError);
                var errorPromise: IPromise<T> = deferred.promise;
                return errorPromise;
            });
        }
    }

    /**
     * In case of a constant call as it's payload will be returned as a promise.
     */
    private handleConstantCall<T>(constantCall: ConstantCall<T>): ng.IPromise<T> {
        if (constantCall.constantPayload instanceof NavigationEvent) {
            var navigationEvent: NavigationEvent = <NavigationEvent>(<any>constantCall.constantPayload);
            this.doRedirect(navigationEvent.link.uri);
        } else if (constantCall.constantPayload instanceof FormSubmitEvent) {
            var formSubmitEvent: FormSubmitEvent = <FormSubmitEvent>(<any>constantCall.constantPayload);
            this.doSubmitForm(formSubmitEvent.link.uri, formSubmitEvent.data);
        }

        return this.$q.when(constantCall.constantPayload);
    }

    private doRedirect(url: string): void {
        CallService.validateUrl(url);
        this.$window.location.href = url;
    }

    private doSubmitForm(url: string, data: any): void {
        CallService.validateUrl(url);
        var postData: { [key: string]: any } = {};
        _.each(data, function (value, key) {
            if (value instanceof Link) {
                postData[key] = (<Link> value).uri;
            } else {
                postData[key] = value;
            }
        });
        this.postUrlService.buildFormAndPostToURL(postData, url);
    }

    private doExec<T>(call: AbstractCall<T>): IPromise<T> {
        argsContract(arguments, 'Call, boolean?');

        var promise: IHttpPromise<T>;
        var headers = {};
        if (call.link.mediaType !== null) {
            headers['Content-Type'] = call.link.mediaType;

        }
        var method;
        var data;
        if (call instanceof PostCall) {
            var jsonData = this.mappingService.toJson((<PostCall<T>>call).data);
            data = JSON.stringify(jsonData);
            method = 'POST';
        } else if (call instanceof GetCall) {
            method = 'GET';
        } else if (call instanceof DeleteCall) {
            method = 'DELETE'
        } else {
            throw new Error('Unknown Method of call: ' + call);
        }

        promise = this.$http(<any>{
            url: call.link.uri,
            method: method,
            data: data,
            headers: headers,
            withCredentials: this.isSendRequestWithCredentials(),
            transformResponse: (data) => {
                // we do not want to throw an Error in case of an empty response - just pass through...
                if (!data || 0 === data.length) {
                    return data;
                }
                return JSON.parse(data);
            }
        });

        return promise.then((response: ng.IHttpPromiseCallbackArg<any>) => {
            return this.mappingService.fromJson(response.data);
        });
    }

    // ugly workaround to fix CORS issues in DEV environments, but only there!
    private isSendRequestWithCredentials(): boolean {
        let metaElement = this.$document.find('meta[name=corsEnabled]');
        if (metaElement.length !== 0) {
            return metaElement.attr("content") === 'true';
        }
    }

    private static validateUrl(url: string): void {
        if (!_.isString(url)) {
            throw new Error('The given URL is not a string!');
        }
    }

}
