import {MetaInfo, valueMetaInfo} from './MetaInfo';
import {Link} from './Link';

export class PiranhaLoginFormDataValue {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:PiranhaLoginFormDataValue', {
            IDToken1: 'string | null',
            IDToken2: 'string | null',
            goto: 'Link | null'
        }
    );

    IDToken1: string;
    IDToken2: string;
    goto: Link;

    setCredentials(login: string, password: string) {
        this.IDToken1 = login;
        this.IDToken2 = password;
    }

}
