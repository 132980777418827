import {coFieldMessage} from "./validation/directive/coField/coFieldMessage";
import {coFieldInput} from "./validation/directive/coField/coFieldInput";
import {coField} from "./validation/directive/coField/coField";
import {coFieldPopover} from "./validation/directive/coField/coFieldPopover";

export function loadModuleCommerceCommonValidation(angular) {
    angular.module('commerce_common_validation', ['commerce_common', 'commerce_common_validation_services'])
        .directive('coField', coField)
        .directive('coFieldInput', coFieldInput)
        .directive('coFieldPopover', coFieldPopover)
        .directive('coFieldMessage', coFieldMessage)
    ;
}
