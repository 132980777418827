import {IDebugInfoService} from './IDebugInfoService';
import * as angular from "angular";


export class DebugInfoService implements IDebugInfoService {

    logDebugInfo(): void {
        var metaTags = angular.element('meta[name^=debugInfo]').each(function () {
            let element = <HTMLMetaElement> this;
            var msg = "DEBUG INFO AVAILABLE: type:'" + element.name + "' info:'" + element.content + "'";
            //older browsers might not support console.log
            if (window.console && window.console.log) {
                window.console.log(msg)
            }
        });
    }
}
