import {IDirective} from "angular";

coSeoHref.$inject = ['renderModeService'];

/**
 * Directive to replace a link when the render mode is prerender.
 */
export function coSeoHref(renderModeService): IDirective {
    return {
        scope: {
            coSeoHrefCall: '=coSeoHrefCall'
        },
        link: function (scope: any, element, attributes) {
            if (renderModeService.isPrerender() && element.prop('tagName') === "A") {
                attributes.$set('href', scope.coSeoHrefCall.constantPayload.link.uri);
            }
        }
    };
}
