import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class StringLengthConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:StringLengthConstraint', {
        key: 'string',
        minLength: 'number | null',
        maxLength: 'number | null'
    });


    public constructor(public key: string, private minLength: number, private maxLength: number) {
    }

    public applies(value: any): boolean {
        if (value == null || value.length == 0) {
            /**
             * if value is not set or length is null,
             * the required validator will take care not the length
             * validator.
             * To prevent false negatives, if no data was entered yet.
             */
            return true;
        }

        if (!value.length) {
            // we probably have something that is not natively a string, e.g. a number
            value = value.toString();
        }

        if (value.length) {
            if (this.isMin()) {
                return value.length >= this.minLength;
            } else if (this.isMax()) {
                return value.length <= this.maxLength;
            } else if (this.isWithin()) {
                return value.length >= this.minLength && value.length <= this.maxLength;
            }
        }
        return false;
    }

    public isWithin(): boolean {
        return this.minLength != null && this.maxLength != null;
    }

    public isMin(): boolean {
        return !this.isWithin() && this.minLength != null;
    }

    public isMax(): boolean {
        return !this.isWithin() && this.maxLength != null;
    }

    public modifyInputField(input: IAugmentedJQuery): void{
        if (this.maxLength){
            input.attr('maxlength', this.maxLength.toString());
        }
    }
}
