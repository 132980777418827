import {FieldController} from './FieldController';
import {IAttributes, IAugmentedJQuery, IDirective, IFormController, IScope} from "angular";

/**
 *
 * This directive marks a 'logical field' on a container element which contains the label and optionally the input of
 * this field.
 *
 * The marked element is hidden by adding 'ng-hide' if the field is configured as invisible.
 */
export function coFieldSemantic(): IDirective {
    return {
        scope: {
            fieldName: '@coField',
        },

        controller: <any>FieldController,
        require: ['coField', '^?form'],
        controllerAs: 'controller',
        bindToController: true,

        link: function (scope: IScope, element: any, attrs: IAttributes, controllers: any[]) {
            let myController: FieldController = controllers[0];
            let formController: IFormController = controllers[1];

            // this style of controller lookup is used by the angular infrastructure to connect a form controller to its parent
            // the normal require handle the lookup for the parent wrong and injects the own controller a second time...
            let parentController: FieldController = (<IAugmentedJQuery>element.parent()).controller('coField');

            // initialise own controller with its parent and formController
            // explicitly pass null to ease debugging
            myController.init(parentController || null, formController || null);

            function updateValidationStatus() {

                // conditions for adding the error class
                let messagesPresent = myController.getMessages().length > 0,
                    fieldTouched = myController.ngModel ? myController.ngModel.$touched : false,
                    formSubmitted = formController && formController.$submitted;

                if (messagesPresent && (fieldTouched || formSubmitted)) {
                    element.addClass('has-error');
                } else {
                    element.removeClass('has-error');
                }
            }

            scope.$watch(
                () => myController.ngModel ? myController.ngModel.$touched : false,
                updateValidationStatus
            );

            scope.$watch(
                () => myController.getMessages(),
                updateValidationStatus,
                true
            );

            /**
             * we need the watchers here, as this is a timing issue.
             */
            // hide element if not visible
            scope.$watch(
                () => (myController.isFieldVisible()),
                (visible: boolean) => {
                    if (!visible) {
                        element.addClass("ng-hide")
                    }
                }
            );

            scope.$watch(
                () => (myController.isFieldEnabled()),
                (enabled: boolean) => {
                    if (!enabled) {
                        element.find("fieldset").attr("disabled", "disabled");
                    }
                }
            );
        }
    };
}
