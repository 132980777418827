import {MetaInfo, valueMetaInfo} from './MetaInfo';

/**
 * Implements a wrapper for tracking data, typically holds a raw json.
 */
export class TrackingInfoValue {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:TrackingInfoValue', {
        rawContent: 'string | null',
    });

    rawContent: string;
}
