import {IExternalService} from './IExternalService';
import * as $ from "jquery";


export class ExternalService implements IExternalService {

    static $inject: string[] = ['$window', '$timeout'];

    constructor(private windowService: ng.IWindowService, private timeoutService: ng.ITimeoutService) {
    }

    public initExternalApps(): void {
        let webApp: any = this.windowService['webapp'];

        if (webApp && webApp.initializePlugins) {
            this.timeoutService(() => {
                webApp.initializePlugins();
                $(document).trigger('tel.app.init');
            }, 0);
        }
    }

}
