import {DebugUiElementController} from "./DebugUiElementController";
import {IComponentOptions} from "angular";
import coDebugUiElementTemplate from "./coDebugUiElementTemplate.html";

export class CoDebugUiElement implements IComponentOptions {
    bindings: any = {
        toggle: '=coDebugUiElementToggle'
    };
    controller: any = DebugUiElementController;
    template: string = coDebugUiElementTemplate;
}
