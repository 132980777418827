import * as _ from "underscore";


export class DebugUiToggleType {
    private static _values: { [s: string]: DebugUiToggleType; } = {};

    static forName(obj): DebugUiToggleType {
        argsContract(arguments, 'str');
        if (obj === null) {
            return null;
        }
        return DebugUiToggleType._values[obj];
    }

    static values(): DebugUiToggleType[] {
        return _.values(DebugUiToggleType._values);
    }

    static BOOLEAN: DebugUiToggleType = new DebugUiToggleType('BOOLEAN');
    static STRING: DebugUiToggleType = new DebugUiToggleType('STRING');

    constructor(public  name: string) {
        argsContract(arguments, 'str');
        DebugUiToggleType._values[name] = this;
    }
}
