import {FieldConfiguration} from './FieldConfiguration';

export interface IValidationRepository {
    findAllFieldConfigurations(): FieldConfiguration[];

    findFieldConfigurationByName(name: string): FieldConfiguration;

    getValidationMessage(key: string): string;
}

export class IValidationRepository {
    static injectableName: string = 'validationRepository';
}
