import {IController, IDirective, IScope} from "angular";
import {ITrackingAttributesService} from "../..";
import {TrackingProductLineController} from "./TrackingProductLineController";
import * as _ from "underscore";

coTrackingAttributes.$inject = ['trackingAttributesService', '$window'];

const TRACKING_ACTION_KEY: string = "data-tracking-action";
const TRACKING_DESCRIPTION_KEY: string = "data-tracking-description";
const ELEMENT_ID_ATTRIBUTE = 'coTrackingAttributesElementId';
const ACTION_ATTRIBUTE = 'coTrackingAttributesAction';

const VALID_TRACKING_ACTION_VALUES: string[] = ['button', 'accordion', 'link', 'radiobutton', 'checkbox', 'close', 'tab', 'form', 'selectionfield'];
const TRACKING_DESCRIPTION_PART_DELIMITER = "_";

/**
 * This directive is used to add two tracking-attributes to a given element.
 * Those attributes are: "data-tracking-action" and "data-tracking-description" (see OSD-2338)
 *
 * - coTrackingAttributesAction: used to provide the type of element this directive is used on (despite its name)
 * - coTrackingElementId: used to provide an ID for the element this directive is used on
 *
 */

export function coTrackingAttributes(trackingAttributesService: ITrackingAttributesService): IDirective {

    return {
        require: ['^?coTrackingProductLines'], // require the next coTrackingProductLiensController, going upward
        link: (scope: IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, controllers: IController[]): void => {

            // since this directive doesn't have a controller, we can access the parent controller at [0]
            const productLinesController = <TrackingProductLineController> controllers[0];

            if (!productLinesController) {
                // if we throw an error in this case all tests must be wrapped in coTrackingProductLines directive
                // so we only log an error and ignore this ...
                console.error("Can't render coTrackingAttributes on element - no coTrackingProductLines directive found any parent element", {element});
                return;
            }

            function buildTrackingDescription(productLineString: string, elementId: string): string {

                const application = trackingAttributesService.getApplication();
                const pageArea = trackingAttributesService.getPageArea();

                if (productLineString && application && pageArea) {
                    return [application, productLineString, pageArea, elementId].join(TRACKING_DESCRIPTION_PART_DELIMITER);
                } else {
                    return null;
                }
            }

            const trackingAction = attrs[ACTION_ATTRIBUTE];
            const elementId = attrs[ELEMENT_ID_ATTRIBUTE];

            // validate bindings
            if (VALID_TRACKING_ACTION_VALUES.indexOf(trackingAction) === -1) {
                throw new Error(`Invalid value for "${trackingAction}" for ${ACTION_ATTRIBUTE}. Valid values: ${VALID_TRACKING_ACTION_VALUES}`);
            }

            if (!elementId) {
                throw new Error(`No value was provided for ${ELEMENT_ID_ATTRIBUTE}. Please provide am ID for the element you are using this directive on.`);
            }

            // debug mode: visually highlight tracking elements and log attribute values on hover
            if (trackingAttributesService.isDebuggingEnabled()) {
                element.css("border", "3px solid #AB47BC");
                element.on("mouseenter", () => {
                    let debugStyle = "background: #AB47BC; color: white; border-radius: 2px;";
                    let trackingDescription = element.attr(TRACKING_DESCRIPTION_KEY);
                    console.log(`%c TRACKING-DEBUG: %c action: ${trackingAction} | description: ${trackingDescription}`, debugStyle, "");
                });
            }

            // since the productLine may change due to user interaction, we have to dynamically update the tracking-description
            scope.$watch(() => productLinesController.productLinesTrackingString, (newProductLineString: string) => {
                const trackingDescription = buildTrackingDescription(newProductLineString, elementId);
                if (trackingDescription) {
                    element.attr(TRACKING_ACTION_KEY, trackingAction);
                    element.attr(TRACKING_DESCRIPTION_KEY, trackingDescription);
                } else {
                    element.attr(TRACKING_ACTION_KEY, undefined);
                    element.attr(TRACKING_DESCRIPTION_KEY, undefined);
                }
            });
        }
    };
}