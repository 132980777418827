import {Call} from '../domain/Call';
import {IPromise} from "angular";

/**
 * This interface is used to abstract a call to the server on a higher level than the $http service.
 */
export interface ICallService {
    handleCall<T>(call: Call<T>, recursionCounter?): IPromise<T>;
}

export class ICallService {
    static injectableName: string = 'callService';
}
