import {IDocumentService} from "angular";

export class RenderModeService {

    static $inject = ['$document'];

    private renderMode;

    constructor($document: IDocumentService) {
        var metaElement = $document.find('meta[name=renderMode]');
        if (metaElement.length !== 0) {
            this.renderMode = metaElement.attr("content");
        }
    }

    isPrerender(): boolean {
        return this.renderMode === 'prerender';
    }
}
