import {IDirective} from "angular";

coSeoHide.$inject = ['renderModeService'];

/**
 * Directive to hide element when the render mode is prerender.
 *
 * @param renderModeService This service provides the current render mode.
 * @returns {{restrict: string, scope: boolean, link: (function(any, any): undefined)}}
 */
export function coSeoHide(renderModeService): IDirective {
    return {
        restrict: 'A',
        scope: false,
        link: function ($scope, $element, attr) {
            var attributeValue = attr['coSeoHide'];

            if (attributeValue === "false") {
                return;
            } else {
                if (renderModeService.isPrerender()) {
                    $element.remove();
                }
            }
        }
    };
}
