import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class NumberRangeConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:NumberRangeConstraint', {
        key: 'string',
        lowerBound: 'number | null',
        upperBound: 'number | null'
    });

    public constructor(public key: string, private lowerBound: number, private upperBound: number) {
    }

    public isRange(): boolean {
        return this.lowerBound != null && this.upperBound != null;
    }

    public isUpperBound() {
        return !this.isRange() && this.upperBound != null;
    }

    public isLowerBound() {
        return !this.isRange() && this.lowerBound != null;
    }

    public applies(value: any): boolean {
        if (value === null || value == undefined || value === "") {
            //required validation is handled elsewhere...
            return true;
        }

        if (!/\d+/.test(value)) {
            // validation should fail if the input is not a number
            return false;
        }

        var number = parseInt(value);

        if (this.isLowerBound()) {
            return this.lowerBound <= number;
        } else if (this.isUpperBound()) {
            return this.upperBound >= number;
        } else if (this.isRange()) {
            return this.lowerBound <= number && this.upperBound >= number;
        }
        return true;
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
