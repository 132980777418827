import {IDirective} from "angular";


// floatLabel directive should not have the "co" prefix, while used to redefine bootstrap data-float-label
export function floatLabel(): IDirective {
    return {
        link: function (scope, element: any, attributes) {

            // hide label elements for inputs with a float-label directive
            element.prevAll('label').hide();

            // find directive and select previous element (the actual label <span>)
            scope.$watch(function () {
                return element.attr('data-float-label')
            }, function (value) {
                element.prev().attr('data-text', value);
            });

            scope.$watch(function () {
                return element.val();
            }, function () {
                if (element.val() === '') {
                    element.parent().addClass('hide-label');
                } else {
                    element.parent().removeClass('hide-label').addClass('js-unhighlight-label');
                }
            });
            element.floatLabel().parent().addClass('js-unhighlight-label');
        }
    };
}
