import {AsyncValidationResult} from './validation/AsyncValidationResult';
import {ValidationStatus} from './validation/ValidationStatus';
import {ConditionalConstraint} from './validation/constraints/ConditionalConstraint';
import {EmailConstraint} from './validation/constraints/EmailConstraint';
import {NameConstraint} from './validation/constraints/NameConstraint';
import {ValidDateConstraint} from './validation/constraints/ValidDateConstraint';
import {DateRangeConstraint} from './validation/constraints/DateRangeConstraint';
import {NumberRangeConstraint} from './validation/constraints/NumberRangeConstraint';
import {PreventSpecialCharNameConstraint} from './validation/constraints/PreventSpecialCharNameConstraint';
import {PreventSpecialCharConstraint} from './validation/constraints/PreventSpecialCharConstraint';
import {RegExpConstraint} from './validation/constraints/RegExpConstraint';
import {RequiredConstraint} from './validation/constraints/RequiredConstraint';
import {StringLengthConstraint} from './validation/constraints/StringLengthConstraint';
import {NumericConstraint} from './validation/constraints/NumericConstraint';
import {AcceptanceConstraint} from './validation/constraints/AcceptanceConstraint';
import {FieldConfiguration} from './validation/FieldConfiguration';
import {IMappingServiceProvider} from './service/IMappingService';
import {CallConstraint} from './validation/constraints/CallConstraint';
import {validationRepositoryProvider} from './validation/ValidationRepositoryProvider';
import {IValidationRepository} from './validation/IValidationRepository';
import {DateExcludedDatesConstraint} from "./validation/constraints/DateExcludedDatesConstraint";
import {DateDaysOfWeekConstraint} from "./validation/constraints/DateDaysOfWeekConstraint";
import {PreventSpecialCharAddressConstraint} from "./validation/constraints/PreventSpecialCharAddressConstraint";
import {ClientsideFieldEqualityConstraint} from "./validation/constraints/ClientsideFieldEqualityConstraint";
import {InvisibleFieldMappedConstraint} from "./validation/constraints/InvisibleFieldMappedConstraint";

export function loadModuleCommerceCommonValidationService(angular: ng.IAngularStatic) {
    angular.module('commerce_common_validation_services', ['commerce_common'])

        .provider(IValidationRepository.injectableName, validationRepositoryProvider)

        .config([IMappingServiceProvider.injectableName, function (mappingServiceProvider: IMappingServiceProvider) {
            mappingServiceProvider.addAnnotatedType(<any>FieldConfiguration);
            mappingServiceProvider.addAnnotatedType(AcceptanceConstraint);
            mappingServiceProvider.addAnnotatedType(NumericConstraint);
            mappingServiceProvider.addAnnotatedType(StringLengthConstraint);
            mappingServiceProvider.addAnnotatedType(RequiredConstraint);
            mappingServiceProvider.addAnnotatedType(RegExpConstraint);
            mappingServiceProvider.addAnnotatedType(PreventSpecialCharConstraint);
            mappingServiceProvider.addAnnotatedType(PreventSpecialCharNameConstraint);
            mappingServiceProvider.addAnnotatedType(PreventSpecialCharAddressConstraint);
            mappingServiceProvider.addAnnotatedType(NumberRangeConstraint);
            mappingServiceProvider.addAnnotatedType(DateRangeConstraint);
            mappingServiceProvider.addAnnotatedType(DateExcludedDatesConstraint);
            mappingServiceProvider.addAnnotatedType(DateDaysOfWeekConstraint);
            mappingServiceProvider.addAnnotatedType(ValidDateConstraint);
            mappingServiceProvider.addAnnotatedType(NameConstraint);
            mappingServiceProvider.addAnnotatedType(EmailConstraint);
            mappingServiceProvider.addAnnotatedType(ConditionalConstraint);
            mappingServiceProvider.addAnnotatedType(CallConstraint);
            mappingServiceProvider.addAnnotatedType(ValidationStatus);
            mappingServiceProvider.addAnnotatedType(AsyncValidationResult);
            mappingServiceProvider.addAnnotatedType(ClientsideFieldEqualityConstraint);
            mappingServiceProvider.addAnnotatedType(InvisibleFieldMappedConstraint);
        }])
    ;

}

