import {DebugUiToggleValue} from "./DebugUiToggleValue";

export interface IDebugUiToggleService {
    toggles: DebugUiToggleValue[];

    setActive(toggle: DebugUiToggleValue, active: boolean);

    isActive(toggle: DebugUiToggleValue): boolean;

    setValue(toggle: DebugUiToggleValue, value: any);

    getValue(toggle: DebugUiToggleValue): any;

    activateAllElements(): void;

    deactivateAllElements(): void;

    allElementsActive(): boolean;

    enabled: boolean;
}

export class IDebugUiToggleService {
    static injectableName: string = 'debugUiToggleService';
}
