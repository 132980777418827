import {AdaptiveState, IAdaptiveService} from './IAdaptiveService';
import {ILogger, ILoggerService} from './LoggerService';
import {IRenderModeService} from './IRenderModeService';
import * as angular from "angular";
import {IRootScopeService} from "angular";

import * as _ from "underscore";


/**
 * The adaptive service offers utilities to work with Bootstrap breakpoints.
 */
export class AdaptiveService implements IAdaptiveService {

    static $inject = ['$window', 'loggerService', '$rootScope', 'renderModeService'];

    private logger: ILogger;
    private watching: boolean;
    private adaptiveState: AdaptiveState;

    constructor(private $window: ng.IWindowService, loggerService: ILoggerService, private $rootScope: IRootScopeService, private renderModeService: IRenderModeService) {
        this.logger = loggerService.create('commerce.common.AdaptiveService');
        this.adaptiveState = new AdaptiveState();
        this.setInitialBreakpoints();

        this.watching = false;
        this.watchViewport();
    }

    /**
     * sets breakpoint sizes. configure breakpoints here.
     */
    private setInitialBreakpoints(): void {
        this.adaptiveState.breakpoints = {
            lg: 1200,
            md: 992,
            sm: 768,
            xs: 480
        }
    }

    /**
     * Set breakpoint for adaptive recognition.
     * @param screenWidth Width of the screen for which to set the breakpoint
     */
    public setBreakpoint(screenWidth: number): void {
        if (_.isUndefined(screenWidth)) {
            this.adaptiveState.currentBreakpoint = undefined;
        }

        this.adaptiveState.currentBreakpoint =
            screenWidth >= this.adaptiveState.breakpoints.lg ? 'lg' :
                screenWidth < this.adaptiveState.breakpoints.lg && screenWidth >= this.adaptiveState.breakpoints.md ? 'md' :
                    screenWidth < this.adaptiveState.breakpoints.md && screenWidth >= this.adaptiveState.breakpoints.sm ? 'sm' : 'xs';
    }

    /**
     * Override breakpoint with the needed value
     * @param breakpoint Example: 'lg' or 'sm'
     */
    public overrideBreakpoint(breakpoint: string) {
        this.adaptiveState.currentBreakpoint = breakpoint;
    }

    /**
     * Return the current breakpoint
     * @returns {string} Example: 'lg' or 'sm'
     */
    public getBreakpoint(): string {
        return this.adaptiveState.currentBreakpoint;
    }

    public getWidthForBreakpoint(breakpoint: string): number {
        return this.adaptiveState.breakpoints[breakpoint];
    }

    /**
     * Check if the current breakpoint corresponds to the entering array of valid breakpoints
     * @param breakpoints Array of breakpoints that are accepted
     * @returns {boolean}
     */
    public checkBreakpoints(breakpoints: string[]): boolean {
        // If we're in prerender mode (snapshotting), always ask as if you're in desktop mode (lg or md breakpoints)
        if (this.renderModeService.isPrerender()) {
            return _.contains(breakpoints, 'lg')
                || _.contains(breakpoints, 'md');
        }

        return _.contains(breakpoints, this.getBreakpoint());
    }

    /**
     * Start the viewport watcher
     */
    private watchViewport(): void {
        if (this.watching) {
            return;
        }
        this.watching = true;

        this.setBreakpoint(this.$window.innerWidth);
        var timeoutRunning = undefined;

        angular.element(this.$window).on('resize', () => {
            // Keep running timeouts until the user is done with resizing
            if (!_.isUndefined(timeoutRunning)) {
                this.$window.clearTimeout(timeoutRunning);
            }

            timeoutRunning = this.$window.setTimeout(() => {
                /*
                 Do a digest loop each time the breakpoint is set.
                 It's needed because the resize element on window doesn't fire an Angular digest loop.
                 There is no danger of multiple digest cycles because we're in setTimeout and
                 the event loop won't allow for multiple digest cycles (stack clearing).
                 */
                this.$rootScope.$apply(() => {
                    this.setBreakpoint(this.$window.innerWidth);
                    timeoutRunning = undefined;
                });
            }, 300);
        });
    }
}
