import {FieldController} from './FieldController';
import {IAttributes, IAugmentedJQuery, IDirective, INgModelController, IScope} from "angular";

/**
 * This directive is used to mark an element that contains the ngModel that should be used by this field.
 *
 */
export function coFieldInput(): IDirective {
    return {
        //get hold of the ngModel
        require: ['ngModel', '^coField'],
        link: function (scope: IScope, element: IAugmentedJQuery, attr: IAttributes, controllers: any[]) {

            // fields requested in order they were defined in 'require'.
            var ngModel: INgModelController = controllers[0];
            var fieldController: FieldController = controllers[1];

            // mark this field with a class for styling errors in a sensible way
            element.addClass('co-field-input');

            //handle the ng-model and input element to the parent form field directive, that is responsible for this form field.
            fieldController.connectCoFieldInput(ngModel, element);


            var placeholder = attr['placeholder'];

            if (placeholder) {
                if (fieldController.isRequired() && placeholder.indexOf('*') !== (placeholder.length - 1)) {
                    attr.$set('placeholder', placeholder + '*');
                }
            }


            function updateValidationStatus() {

                var messages = fieldController.getMessages();

                if (messages.length > 0) {
                    element.addClass('co-invalid');
                    element.removeClass('co-valid');
                } else {
                    element.addClass('co-valid');
                    element.removeClass('co-invalid');
                }

            }

            scope.$watch(() => {
                    return fieldController.getMessages();
                },
                updateValidationStatus,
                true
            );

            /**
             * This is kind of a hack.
             * If you validate complex models (not just strings) you need to
             * react on changes in this model.
             *
             * For example:
             *
             * You validate an address object, so you want the validation to be
             * triggered again if for example the street changes.
             *
             * Angular does not do deep model watches, hence the validation
             * will only be triggered if the complete address object is switched.
             * Alternatively you can set co-field-input-deep-watch-model to "true",
             * angular will automatically do a deep watch on this model.
             */
            if (attr['coFieldInputDeepWatchModel']) {
                scope.$watch(() => {
                        return ngModel.$modelValue;
                    }, () => {
                        ngModel.$validate();
                        ngModel.$setDirty();
                    },
                    true
                )
            }

        }

    };

}
