import {TrackingInfoValue} from '../domain/TrackingInfoValue';
import {Call} from '../domain/Call';
import {IPromise} from "angular";

/**
 * Tracking Service
 */
export interface ITrackingService {
    /**
     * allows to track the given data.
     * @param data
     */
    track(call: Call<TrackingInfoValue>): IPromise<any>;
}

export class ITrackingService {
};
