/**
 * This Service resolves the render mode for the current request.
 */
export interface IRenderModeService {
    /**
     * Returns true if the current render mode if 'prerender'.
     */
    isPrerender(): boolean;


}

export class IRenderModeService {
}
