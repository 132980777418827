/**
 * can be appended to any form component and triggers a scroll to the top of the page.
 * In contrast to coScrollTo-Directive does not prevent the original event to bubble up.
 */
import {IDirective} from "angular";

export function coScrollToTop(): IDirective {
    return {
        scope: false,
        restrict: 'A',
        link: function (scope: any, element, attrs: any) {
            element.on('click', (e) => {
                $("body,html").animate({scrollTop: 0}, 0);
            });
        }
    };
}
