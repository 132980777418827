/*
* Property decorator which uses reflection to determine the type of the entering property from the page value.
* If there should be another check, an optional paramtere can be provided.
* */
export function ValueMetaProperty(metaProperty?: string) {
    return function (target: any, key: any) {
        if (!metaProperty) {
            let metaData = Reflect.getMetadata('design:type', target, key).name;

            switch (metaData) {
                case 'String':
                    metaData = 'string';
                    break;
                case 'Number':
                    metaData = 'number';
                    break;
                case 'Boolean':
                    metaData = 'boolean';
                    break;
            }

            metaProperty = metaProperty || metaData;
        }

        target.constructor['metaInfoObject'] = target.constructor['metaInfoObject'] || {};
        target.constructor['metaInfoObject'][key] = metaProperty;
    }
}
