import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

/**
 * requires that something has to be accepted.
 * In technical terms, one could say required the value to be truthy - but in
 * our domain, this requires someone to accept for example terms and conditions.
 *
 * technically, you could think that the required-constraint fits better but -
 * required means, something has to be set - in case of a boolean value, there is no unset -
 * its either true or false - both are not undefined, thus the required constraint will never fail.
 * (I know, i fell trap to it too).
 */
export class AcceptanceConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:AcceptanceConstraint', {
        key: 'string'
    });

    constructor(public key: string) {
    }

    public applies(value: any): boolean {
        return value == true;
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
