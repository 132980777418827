import {ITrackingService} from './ITrackingService';
import {ICallService} from './ICallService';
import {ILogger, ILoggerService} from './LoggerService';
import {TrackingInfoValue} from '../domain/TrackingInfoValue';
import {Call} from '../domain/Call';
import {IPromise, IWindowService} from "angular";
import * as _ from "underscore";


export class TrackingService implements ITrackingService {

    static $inject = ['callService', '$window', 'loggerService'];

    private logger: ILogger;

    constructor(private callService: ICallService, private $window: IWindowService, private loggerService: ILoggerService) {
        this.logger = loggerService.create('commerce.common.ITrackingService');
    }

    track(call: Call<TrackingInfoValue>): IPromise<any> {
        if (_.isNull(call) || _.isUndefined(call)) {
            return;
        }

        //resolve the call to get the tracking info
        var promise: IPromise<TrackingInfoValue> = this.callService.handleCall(call);
        return promise.then((result: TrackingInfoValue) => {
            //when tracking data was resolved
            if (this.$window['tefDataLayer']) {
                try {
                    this.logger.info("Logging Event : ", {
                        'content': result.rawContent
                    });
                    //push to tefDataLayer
                    this.$window['tefDataLayer'].push(JSON.parse(result.rawContent));
                } catch (e) {
                    //ignore by intention
                    this.logger.error(e);
                }
            }
        });
    }

}
