import {IFeedbackService} from './IFeedbackService';


export class FeedbackService implements IFeedbackService {

    messages = new FeedbackMessages();

    constructor() {
    }

    info(message: string): void {
        this.pushMessage(this.messages.info, message);
    }

    warn(message: string): void {
        this.pushMessage(this.messages.warn, message);
    }

    error(message: string): void {
        this.pushMessage(this.messages.error, message);
    }

    private pushMessage(list: string[], message: string) {
        if (list.indexOf(message) === -1) {
            list.push(message);
        }
    }

    isEmpty(): boolean {
        return (
            Object
                .keys(this.messages)
                .every(property => this.messages[property].length == 0)
        );
    }

    clear(): void {
        (Object
                .keys(this.messages)
                .map(property => this.messages[property].length = 0)
        );
    }
}

// full fledged class for typing the directive logic
export class FeedbackMessages {
    info: string[] = [];
    warn: string[] = [];
    error: string[] = [];
}
