import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class RequiredConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:RequiredConstraint', {
        key: 'string'
    });

    constructor(public key: string) {
    }

    public applies(value: any): boolean {
        return value != null && value !== undefined && value !== "";
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
