import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

/**
 * This constraint seems useless because it always returns true and if the rules are configured correctly, this
 * constraint only appears on invisible fields and will then not even be added to ngModel by the FieldController.
 * This constraint only exists because the Configuration for the frontend validation has to be the same as the
 * configuration for the backend validation. In the backend the matching constraint checks if invisible fields have
 * been mapped to the order.
 */
export class InvisibleFieldMappedConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:InvisibleFieldMappedConstraint', {
        key: 'string'
    });

    public constructor(public key: string) {
    }

    public applies(value: any): boolean {
        return true;
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
