import {IConstraint} from './IConstraint';
import {MetaInfo, valueMetaInfo} from '../../domain/MetaInfo';
import {IAugmentedJQuery} from "angular";

export class ConditionalConstraint implements IConstraint {

    static META_INFO: MetaInfo = valueMetaInfo('infrastructure:ConditionalConstraint', {
        key: 'string',
        keyPrefix: 'string',
        constraint: '{}',
        pattern: 'string',
        matchesPattern: 'boolean'
    });

    private regexp: RegExp;

    constructor(public key: string, private keyPrefix, private pattern: string, private constraint: IConstraint, private matchesPattern: boolean) {
    }

    public applies(value: any): boolean {
        var conditionMatch = this.getRegex().test(value);
        if (this.matchesPattern ? conditionMatch : !conditionMatch) {
            return this.constraint.applies(value);
        }

        return true
    }

    private getRegex(): RegExp {
        if (!this.regexp) {
            this.regexp = new RegExp(this.pattern);
        }
        return this.regexp;
    }

    public modifyInputField(input: IAugmentedJQuery): void{}
}
